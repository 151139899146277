import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon {
      display: flex;
    }
  }

  .ant-input {
    width: 50px;
    text-align: center;
    font-size: 14px;
  }
`;
