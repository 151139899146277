import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import i18n from 'i18next';
import { Form, Button, Select } from 'antd';
// import actions
import { actions } from 'redux/reports';
// import components
import Title from 'components/Title';
// import containers
import { TheFarmerStyles } from './styles';

const { Option } = Select;

const TheFarmer = ({ form, onBack, newReportsToSync, onNext, step }) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const dispatch = useDispatch();
  const report = useSelector(state => state.reports.currentNewReport);
  const currentNewReport = newReportsToSync || report;
  const listFarmers = useSelector(state => state.setting.listFarmers);
  const { getFieldDecorator } = form;

  const createNewFarmer = () => {
    const values = {
      forecastMonth: currentNewReport.forecastMonth,
      forecastYear: currentNewReport.forecastYear,
    };
    dispatch(actions.clearNewReport());
    dispatch(actions.addNewReport(values));
    onNext();
  };

  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        setIsSubmiting(true);

        const currentFarmer = find(listFarmers, farmer => {
          if (farmer.id) {
            return farmer.id == values.farmerId;
          }
          return farmer.syncOfflineFarmerId == values.farmerId;
        });

        const hydratePlotsValues = currentFarmer.parcelles.map(plot => {
          return {
            plotId: plot.id,
            farmerIsland: plot.commune.ile.id,
            farmerMunicipality: plot.commune.id,
            farmerCultureMethod: plot.methode_culture.id,
            farmerTypeActivity: plot.types_activites.map(activity => {
              return activity.id
            }),
            farmerTypeCulture: plot.type_culture.id,
            farmerPlotArea: plot.superficie,
            farmerFutherInfo: plot.informations_complementaires,
          };
        });

        const hydrateValues = {
          farmerId: currentFarmer.id,
          farmerName: currentFarmer.nom,
          farmerFirstName: currentFarmer.prenom,
          farmerPhoneNumber: currentFarmer.portable,
          farmerHomePhone: currentFarmer.fixe,
          farmerEmail: currentFarmer.email,
          farmerSecondaryEmail: currentFarmer.email_secondaire,
          farmerSocialSecurity: currentFarmer.type_regime_social?.id,
          farmerNumberEmployees: currentFarmer.employes,
          farmerFutherInfo: currentFarmer.informations_complementaires,
          parcelles: hydratePlotsValues,
        };

        if (newReportsToSync) {
          dispatch(
            actions.updateNewReportToSync({
              ...newReportsToSync,
              ...hydrateValues,
            }),
          );
          onNext();
          return;
        }

        dispatch(actions.addNewReport(hydrateValues))
          .then(() => {
            setIsSubmiting(false);
            onNext();
          })
          .catch(() => {
            setIsSubmiting(false);
          });
      }
    });
  };

  return (
    <TheFarmerStyles>
      <Title
        title={i18n.t('createReport.theFarmerStep.title', { step })}
        subtitle={i18n.t('createReport.theFarmerStep.subTitle')}
      />
      <div className="form-content">
        <Form layout="vertical">
          <Form.Item label={i18n.t('createReport.theFarmerStep.farmer')}>
            {getFieldDecorator('farmerId', {
              rules: [{ required: true }],
              initialValue: currentNewReport?.farmerId,
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {listFarmers?.map((farmer, index) => (
                  <Option
                    key={index}
                    value={farmer.id ? farmer.id : farmer.syncOfflineFarmerId}
                  >
                    {`${farmer.nom} ${farmer.prenom}`}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
        <div className="list-actions-section">
          <div className="action-section">
            <Button
              type="primary"
              className="blue-btn"
              onClick={createNewFarmer}
            >
              {i18n.t('createReport.theFarmerStep.createNewFarmer')}
            </Button>
          </div>
          <div className="action-section">
            <Button type="primary" className="cancel-btn" onClick={onBack}>
              {i18n.t('createReport.cancelBtn')}
            </Button>
            <Button type="primary" onClick={handleSubmit} loading={isSubmiting}>
              {i18n.t('createReport.nextBtn')}
            </Button>
          </div>
        </div>
      </div>
    </TheFarmerStyles>
  );
};

TheFarmer.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  form: PropTypes.object,
  newReportsToSync: PropTypes.object,
  step: PropTypes.number,
};

export default Form.create({ name: 'the_farmer_step' })(TheFarmer);
