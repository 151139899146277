import styled from 'styled-components';
import theme from 'configs/theme';

export default styled.div`
  .title-section {
    text-align: center;
    margin: 15px 0 35px;
    color: ${theme.palette.darkBlue};

    .title {
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  .ant-form {
    display: flex;
    justify-content: center;
    margin: 45px 0 50px;
    flex-wrap: wrap;
    max-width: 100%;

    .ant-form-item {
      width: 300px;
      margin: 0 35px;
    }
    
    .ant-form-item.detail {
      width: auto !important;
      margin: inherit;
    }


    .ant-form-item.futherInfo {
      width: 100%;
    }
  }

  .action-section {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button {
      margin: 20px;
    }
    .cancel-btn {
      background-color: ${theme.palette.red};
      border-color: ${theme.palette.red};
    }
  }

  .red-btn {
    background-color: ${theme.palette.red};
    border-color: ${theme.palette.red};
  }

  .create-report-tab {
    .ant-tabs-bar {
      display: none;
    }
  }

  .ant-input-number {
    width: 100%;

    input {
      text-align: center;
    }
  }
`;
