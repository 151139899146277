import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import StyledUserInfo from './styles';

const Card = ({ data }) => {
  return (
    <StyledUserInfo>
      <div className="report-card">
        <div className="job tit">{i18n.t('homepage.farmerTitle')}</div>
        <div className="name">{`${data?.nom} ${data?.prenom}`}</div>
        <br />
        <div className="date">{data?.email}</div>
        <br />
        <Link to={`/farmers/${data.id}`}>
          <Button type="primary">{i18n.t('homepage.openBtn')}</Button>
        </Link>
      </div>
    </StyledUserInfo>
  );
};

Card.propTypes = {
  data: PropTypes.object,
};

export default Card;
