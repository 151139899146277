import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs } from 'antd';
// import containers
import ForecastPeriodStep from 'containers/CreateNewReport/ForecastPeriodStep';
import TheFarmer from 'containers/CreateNewReport/TheFarmer';
import TheNewFarmer from 'containers/CreateNewReport/TheNewFarmer';
import EstimationForecastsFarmer from 'containers/CreateNewReport/EstimationForecastsFarmer';
import EstimationForecastsAgent from 'containers/CreateNewReport/EstimationForecastsAgent';
import Summary from 'containers/CreateNewReport/Summary';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import CreateNewReportStyles from './styles';

const { TabPane } = Tabs;

const listTabComponents = [
  ForecastPeriodStep,
  TheFarmer,
  TheNewFarmer,
  EstimationForecastsFarmer,
  EstimationForecastsAgent,
  Summary,
];

const updateTabComponents = [
  ForecastPeriodStep,
  TheNewFarmer,
  EstimationForecastsFarmer,
  EstimationForecastsAgent,
  Summary,
];

const CreateNewReport = () => {
  const [activeKey, setActiveKey] = useState(0);
  const history = useHistory();
  const { offlineId } = useParams();

  const newReportsToSync = useSelector((state) =>
    find(state.reports.listNewReportsToSync, (e) => `${e.id}` === offlineId),
  );

  const onBack = (index) => () => {
    if (index) {
      setActiveKey(index - 1);
    } else {
      history.push('/');
    }
  };

  const onNext = (index) => () => {
    if (
      index ===
      (newReportsToSync ? updateTabComponents : listTabComponents).length - 1
    ) {
      history.push('/');
    } else {
      setActiveKey(index + 1);
    }
  };

  return (
    <CreateNewReportStyles>
      <Tabs
        // destroyInactiveTabPane
        activeKey={`${activeKey}`}
        className="create-report-tab"
      >
        {(newReportsToSync ? updateTabComponents : listTabComponents).map(
          (Component, index) => (
            <TabPane tab={`Tab ${index}`} key={index}>
              <Component
                step={index + 1}
                activeKey={activeKey}
                newReportsToSync={newReportsToSync}
                onNext={onNext(index)}
                onBack={onBack(index)}
              />
            </TabPane>
          ),
        )}
      </Tabs>
    </CreateNewReportStyles>
  );
};

export default CreateNewReport;
