import React from "react";
import StyledTitle from "./styles";

const Title = ({title, subtitle}) => {
    return (
      <StyledTitle>
        {title &&
        <div className="title">
          {title}
        </div>}

        {subtitle &&
        <div className="subtitle">
          {subtitle}
        </div>}
      </StyledTitle>
    );
};

export default Title;
