// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import i18n from 'i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { filter, orderBy } from 'lodash';
// import components
import UserInfoCard from 'components/UserInfo';
import Title from 'components/Title';
import GoBackButton from "../../components/GoBackButton";
// import containers
import DashboardWrapper from './styles';

const Dashboard = () => {
  const { farmerId } = useParams();
  const reports = useSelector(state =>
    filter(
      state.reports.listReports,
      report => report?.agriculteur?.id == farmerId,
    ),
  );

  const currentFarmer = useSelector(state =>
    filter(
      state.setting.listFarmers,
      farrmer => farrmer?.id == farmerId,
    ),
  );

  const reportsByDate = orderBy(reports, ['id'], ['asc']);

  return (
    <DashboardWrapper>
      <Title title={`${i18n.t('homepage.reports')} : ${currentFarmer[0].nom} ${currentFarmer[0].prenom}`} />
      <div className="content-section">
        {reportsByDate.map(data => (
          <UserInfoCard data={data} />
        ))}
      </div>

      <GoBackButton />
    </DashboardWrapper>
  );
};

export default Dashboard;
