import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Form, Icon, Button } from 'antd';
import i18n from 'i18next';
import MaterialInput from 'components/MaterialInput';
// import actions
import { authActions } from 'redux/auth';

const { requestResetPassword } = authActions;
const FormItem = Form.Item;

const ResetPassword = ({ form }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const dispatch = useDispatch();
  const { getFieldDecorator } = form;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setIsLoadingBtn(true);
        dispatch(requestResetPassword(values)).then(data => {
          setIsLoadingBtn(false);
        });
      }
    });
  };

  return (
    <div>
      <div className="title">
        <span>{i18n.t('login.resetPasswordTitle')}</span>
      </div>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [{ required: true }],
          })(
            <MaterialInput
              placeholder={i18n.t('login.username')}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />,
          )}
        </FormItem>
        <div className="action-div">
          <Button
            type="primary"
            htmlType="submit"
            className="reset-form-button"
            loading={isLoadingBtn}
          >
            {i18n.t('login.resetBtn')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

ResetPassword.propTypes = {
  form: PropTypes.object,
};

export default Form.create()(ResetPassword);
