import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import StyledUserInfo from './styles';

const Card = ({ data = null, dataToSync = null }) => {
  const isSuccess = data ? 'success' : 'error';
  const lastName = data ? data.agriculteur.nom : dataToSync.farmerName;
  const firstName = data ? data.agriculteur.prenom : dataToSync.farmerFirstName;
  const date = data ? data.date_rapport : `${dataToSync.forecastMonth}/${dataToSync.forecastYear}`;
  const id = data ? data.id : dataToSync.id;

  return (
    <StyledUserInfo>
      <div className="report-card">
        <div className={`status-dot ${isSuccess}`} />
        <div className="job tit">{i18n.t('homepage.farmerTitle')}</div>
        <div className="name">
          {lastName} {firstName}
        </div>
        <br />
        <div className="date">{date}</div>
        <br />
        <Link to={data ? `/reports/${id}` : `/sync-reports/${id}`}>
          <Button type="primary">{i18n.t('homepage.openBtn')}</Button>
        </Link>
      </div>
    </StyledUserInfo>
  );
};

Card.propTypes = {
  data: PropTypes.object,
};

export default Card;
