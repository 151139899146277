import {createGlobalStyle} from 'styled-components';
import theme from 'configs/theme';

const GlobalStyle = createGlobalStyle`
  .route-modal{
    .ant-drawer-header {
      .ant-page-header {
        padding: 0;

        .ant-page-header-heading-title{
          font-weight: 500;
        }
      }
    }
  }
  
  .form-section {
     .title-custom {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 5px;
     }
  }
  
  .ant-form-vertical, .form-section {
    .title {
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 10px;
    }
    
    .label-custom {
      font-size: 18px;
      font-weight: 600;
      display: inline-block;
    }
    
    .value-custom {
       margin-left: 3px;
      display: inline-block;
    }
    
    
  }

  .menu-drawer {
    color: white;

    .ant-drawer-header {
      background-color: ${theme.palette.primaryColor};
      border: none;

      .title-section {
        font-size: ${theme.fontSize.normal};
        color: white;
        text-align: center;
      }
    }

    .ant-drawer-body {
      padding: 0;

      .logout-section {
        padding: 15px;
        background-color: ${theme.palette.darkBlue};
        text-align: center;
        cursor: pointer;
        font-size: ${theme.fontSize.normal};

        span {
          margin-right: 10px;
        }
      }

      .menu-section {
        margin: 0 1px;
        .ant-menu-item {
          font-size: ${theme.fontSize.normal};
          height: ${theme.items.height};
          line-height: ${theme.items.height};
          .anticon {
            font-size: ${theme.fontSize.normal};
            margin-right: 15px;
          }
        }
      }
    }
  }

  .blue-btn {
    background-color: ${theme.palette.blue};
    border-color: ${theme.palette.blue};

    &:hover {
      background-color: ${theme.palette.blue};
      border-color: ${theme.palette.blue};
    }
  }

  .full-screen-drawer {
    .ant-drawer-header {
      display: none;
    }
    .ant-drawer-wrapper-body {
      background-color: ${theme.palette.backgroundColor};
    }
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .list-actions-section {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
  }
  
  .ant-btn {
    height: ${theme.items.height};
    padding: 0 30px;
    border-radius: 0;
    font-size: ${theme.fontSize.normal};
  }
  
  .ant-form label {
     font-size: ${theme.fontSize.normal};
   } //size of label title for form-item
  .ant-form-item-required::before {
     font-size: ${theme.fontSize.normal};
   } //size of '*' for form-item required
  .ant-select-dropdown-menu-item {
    font-size: ${theme.fontSize.normal};
    line-height: calc(${theme.fontSize.normal} + 8px);
  } //size of dropdown items from form-item
  .ant-form-explain { font-size: ${theme.fontSize.small} } //size of label required form-item
`;

export default GlobalStyle;
