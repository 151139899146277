import styled from 'styled-components';
import theme from 'configs/theme';

export const TheFarmerStyles = styled.div`
  .list-actions-section {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
  }
`;

export const TheNewFarmerStyles = styled.div`
  .ant-form {
    display: block !important;

    .info-section {
      flex: 1;
      max-width: 1120px;
      margin: auto auto 50px;

      .title-sub-section {
        font-size: ${theme.fontSize.normal};
        font-weight: bold;
        margin: 0 35px;
      }

      .content-sub-section {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .parcelle-form {
    padding: 20px 0;
    border-bottom: silver solid thin;

    &:last-child {
      border-bottom: none;
    }
  }

  .right-btn {
    float: right;
  }
`;

export const ParcelleFormStyles = styled.div`
  .form-div {
    display: flex;
    flex-wrap: wrap;
  }
  .action-div {
    display: flex;
    margin: 0 35px;
    flex-direction: row-reverse;
  }
`;

export const ParcelleDetailFormStyles = styled.div`
  margin: 25px 0 35px;

  .no-product {
    font-size: ${theme.fontSize.normal};
    font-weight: bold;
    text-align: center;
    margin: 15px 0;
  }

  .ant-form-item {
    width: 33% !important;
  }

  .remove-product-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 25px;
  }
`;

export const ForecastPeriodStepStyles = styled.div``;

export const EstimatedMarketingStyles = styled.div`
  .no-product {
    font-size: ${theme.fontSize.normal};
    font-weight: bold;
    text-align: center;
    margin: 60px 0 80px;
  }

  .list-actions-section {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
  }

  .list-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-section {
    width: 200px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 5px;
    padding: 35px 20px;
    margin: 15px 25px;
    position: relative;

    .remove-product-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      cursor: pointer;
    }

    img {
      border-radius: 50%;
    }

    .name {
      font-size: ${theme.fontSize.normal};
      font-weight: bold;
      margin: 10px 0;
    }

    .quantity-title,
    .quantity-info {
      font-size: ${theme.fontSize.small};
      margin: 15px 0;
    }

    .quantity-info {
      .ant-form-item {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }
`;

export const AddProductPopupStyles = styled.div`
  .title-section {
    text-align: center;

    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .content-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .product-section {
      margin: 15px 20px;

      .content-product-section {
        min-height: 200px;
      }

      img {
        border-radius: 50%;
      }
      .name {
        font-size: ${theme.fontSize.normal};
        font-weight: bold;
        margin-top: 10px;
      }

      .ant-checkbox-group {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  .close-div {
    font-weight: bold;
    text-align: right;

    span {
      cursor: pointer;
    }
  }

  .label {
    display: block;
    text-align: center;
    min-height: 55px;
    margin: 10px;
    width: 200px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    a {
      position: absolute;
      top: 0;
      right: 10px;
    }

    .answer-text {
      div {
        display: inline-block !important;
        img {
          width: 30px;
        }
      }
    }
  }
  .label > input,
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    /* HIDE RADIO */
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
  }

  .label > input + div,
  .ant-radio-wrapper + div,
  .ant-checkbox-wrapper + div {
    /* DIV STYLES */
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 30px 45px;
    align-items: center;
    justify-content: center;
    word-break: break-word;
  }

  .label > input:checked + div,
  .ant-radio-wrapper-checked + div,
  .ant-checkbox-wrapper-checked + div {
    /* (RADIO CHECKED) DIV STYLES */
    background-color: #003750;

    .name {
      color: #fff;
    }
  }
`;

export const AddProductForParcellesPopupStyles = styled.div`
  .title-section {
    text-align: center;

    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .content-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .product-section {
      margin: 15px 20px;

      .content-product-section {
        min-height: 200px;
      }

      img {
        border-radius: 50%;
      }
      .name {
        font-size: 17px;
        font-weight: bold;
        margin-top: 10px;
      }

      .ant-checkbox-group {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  .close-div {
    font-weight: bold;
    text-align: right;

    span {
      cursor: pointer;
    }
  }

  .label {
    display: block;
    text-align: center;
    min-height: 55px;
    margin: 10px;
    width: 200px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    a {
      position: absolute;
      top: 0;
      right: 10px;
    }

    .answer-text {
      div {
        display: inline-block !important;
        img {
          width: 30px;
        }
      }
    }
  }

  .label > input,
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    /* HIDE RADIO */
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
  }

  .label > input + div,
  .ant-radio-wrapper + div,
  .ant-checkbox-wrapper + div {
    /* DIV STYLES */
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 40px 45px;
    align-items: center;
    justify-content: center;
    word-break: break-word;
  }

  .label > input:checked + div,
  .ant-radio-wrapper-checked + div,
  .ant-checkbox-wrapper-checked + div {
    /* (RADIO CHECKED) DIV STYLES */
    background-color: #003750;

    .name {
      color: #fff;
    }
  }
`;

export const EstimationForecastsStyles = styled.div`
  .content-section {
    margin: 0 25px;

    .title-div {
      .title {
        font-size: ${theme.fontSize.subtitle};
        font-weight: bold;
        line-height: 30px;
      }

      .subTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info-section {
          .info {
            font-size: ${theme.fontSize.normal};
            font-weight: bold;
          }
        }

        .action-section {
          flex-wrap: wrap;
          button {
            margin-top: 0;
            margin-bottom: 0;
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .product-form {
      display: flex;
      flex: 1;
      margin: 20px 0;
      padding: 0 30px;
      background-color: rgba(255, 255, 255, 0.65);
      border-radius: 10px;
      position: relative;
      .ant-upload-list-item-info {
        display: none;
      }
      .ic-close {
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 28px;
        cursor: pointer;
        color: #ce0404;
        &:hover {
          transform: scale(1.2);
        }
      }
      .ic-camera {
        position: absolute;
        top: 15px;
        left: 20px;
        font-size: 30px;
        cursor: pointer;
        color: ${theme.palette.darkBlue};
        &:hover {
          transform: scale(1.2);
        }
      }

      .img-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px 0 25px;

        img {
          border-radius: 50%;
          width: 80px;
        }

        .name {
          font-size: 18px;
          font-weight: bold;
          margin: 20px 0;
        }
      }

      .ant-form {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
`;

export const SummaryStyles = styled.div`
  .title-summary {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 15px 0 25px;
  }

  .list-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-section {
    margin: 0 25px;

    .title-div {
      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
      }

      .subTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info-section {
          .info {
            font-size: 16px;
            font-weight: bold;
          }
        }

        .action-section {
          flex-wrap: wrap;
          button {
            margin-bottom: 0;
            margin-top: 0;
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .product-form {
      display: flex;
      flex: 1;
      margin: 20px 0;
      padding: 0 30px;
      background-color: rgba(255, 255, 255, 0.65);
      border-radius: 10px;

      .img-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px 0 25px;

        img {
          border-radius: 50%;
          width: 80px;
        }

        .name {
          font-size: 18px;
          font-weight: bold;
          margin: 20px 0;
        }
      }

      .ant-form {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .backBtn {
    margin: auto;
    display: flex;
  }
`;
