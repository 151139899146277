import styled from 'styled-components';
import theme from 'configs/theme';

export default styled.div`
  .product-section {
    width: 200px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 5px;
    padding: 35px 20px;
    margin: 15px 25px;

    img {
      border-radius: 50%;
    }

    .name {
      font-size: ${theme.fontSize.normal};
      font-weight: bold;
      margin: 10px 0;
    }

    .quantity-title,
    .quantity-info {
      margin: 15px 0;
    }
  }
`;
