import styled from 'styled-components';
import theme from 'configs/theme';

const PrivateLayoutWrapper = styled.div`
  .trigger {
    font-size: ${theme.icon.large};
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .logo {
    height: 32px;
    background: rgba(0, 0, 0, 0.2);
    margin: 16px;
  }

  .sidebar {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    background: #fff;
    box-shadow: 1px 0px 0px 0px #e8e8e8;

    .ant-menu {
      border-right: 1px solid #fff;
    }
  }

  .ant-layout-header {
     height: 80px;
     line-height: 80px;
   }

  .header {
    background: ${theme.palette.darkBlue};
    color: #fff;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    font-size: ${theme.fontSize.subtitle};

    & .ant-avatar-lg.ant-avatar-icon {
      .anticon {
        font-size: 24px;
      }
    }
  }

  .mainContent {
    padding: 20px;
    background: #fff;
    min-height: 280px;
  }

  .footer {
    text-align: center;
    font-size: ${theme.fontSize.subtitle};
  }

  .main-layout {
    min-height: 100vh;
  }
`;

export default PrivateLayoutWrapper;
