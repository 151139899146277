import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// We'll use redux-logger just as an example of adding another middleware
import logger from 'redux-logger';
// And use redux-batch as an example of adding enhancers
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import deferredMiddleware from 'redux/middlewares/ExposedPromiseMiddleware';
import offlineMiddleware, {
  afterReloadOfflineMiddleware,
} from 'redux/middlewares/OfflineMiddleware';
import reducer from './reducers';
import rootSaga from './sagas';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router'],
  // whitelist: ['reports', 'setting'],
};

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, reducer(history));
const store = configureStore({
  middleware: [
    deferredMiddleware,
    offlineMiddleware,
    ...getDefaultMiddleware(),
    routerMiddleware(history),
    sagaMiddleware,
    // logger,
    afterReloadOfflineMiddleware,
  ],
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [],
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
