import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import { Modal, Button } from 'antd';

const ImageCropper = ({ file, onChangePreview, isShowModal, onHideModal }) => {
  const [setting, setSetting] = useState({
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    preview: null,
    width: 200,
    height: 200,
  });
  const editor = useRef(null);

  const handleSave = () => {
    onChangePreview({
      croppedFile: editor.current.getImageScaledToCanvas().toDataURL(),
    });
    onHideModal();
  };
  const handleScale = e => {
    const scale = parseFloat(e.target.value);
    setSetting({ ...setting, scale });
  };

  return (
    <div>
      <div className="static-modal">
        <Modal
          visible={isShowModal}
          onCancel={onHideModal}
          className="avatar-editor-modal"
          title="Edit Avatar"
          bodyStyle={{ textAlign: 'center' }}
          footer={[
            <Button
              className="btn-fill btn-wd"
              type="primary"
              onClick={handleSave}
              key="saveBtn"
            >
              Save
            </Button>,
          ]}
        >
          <AvatarEditor
            ref={editor}
            width={250}
            height={250}
            border={50}
            color={[0, 0, 0, 0.6]} // RGBA
            scale={parseFloat(setting.scale)}
            rotate={0}
            borderRadius={0}
            image={file}
          />
          <br />
          Zoom:
          <div className="zoom-div">
            <input
              name="scale"
              type="range"
              onChange={handleScale}
              min="1"
              max="2"
              step="0.01"
              defaultValue="1"
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

ImageCropper.propTypes = {
  onChangePreview: PropTypes.func,
  file: PropTypes.object,
  isShowModal: PropTypes.bool,
  onHideModal: PropTypes.func,
};

export default ImageCropper;
