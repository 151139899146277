import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import i18n from 'i18next';
// import containers
import {ParcelleDetailSummaryStyles} from './styles';

const ParcelleDetailSummary = ({data, index, estimations_agents}) => {
  const typeActivites = data.types_activites?.map(activite => {
    return activite.nom + " ";
  });

  return (
    <ParcelleDetailSummaryStyles>
      <div className="title-div">
        <div className="title">
          {`${i18n.t('createReport.estimationForecastsStep.parcel')} situé à :`}
        </div>
        <div className="subTitle">
          <div className="info-section">
            <div className="info">
              {`${data.commune?.ile?.nom} / ${data.commune?.nom} / ${data.methode_culture?.nom} / ${typeActivites} / ${data.type_culture?.nom} / ${data.superficie} m2`}
            </div>
          </div>
        </div>
      </div>

      {data.estimations_agents?.map((estimationAgent, i) => (
        <div className="product-form">
          <div className="img-section">
            <img
              src={
                localStorage.getItem(estimationAgent.produit.photo) ||
                `${process.env.REACT_APP_CLOUDINARY_URL}/w_400,h_400,c_crop,g_face,r_max/w_100/${estimationAgent.produit.photo}`
              }
              alt={estimationAgent.produit.nom}
              title={estimationAgent.produit.nom}
            />
            <div className="name">{estimationAgent.produit.nom}</div>
          </div>
          <div className="form-section">
            <Row gutter={[16, 16]}>
              <Col lg={24}>
                <div className="title-custom">
                  Informations générales
                </div>
              </Col>

              {estimationAgent.date_plantation &&
              <Col lg={12}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.plantingDate')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.date_plantation}
                </div>
              </Col>
              }

              {estimationAgent.date_recolte &&
              <Col lg={12}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.estimatedHarvestDate')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.date_recolte}
                </div>
              </Col>
              }

              {estimationAgent.surface &&
              <Col lg={12}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.plantedArea')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.surface}
                </div>
              </Col>
              }

              {estimationAgent.etat &&
              <Col lg={12}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.productionStatus')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.etat}
                </div>
              </Col>
              }

              {estimationAgent.rendement_kg &&
              <Col lg={12}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.averageYield')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.rendement_kg}
                </div>
              </Col>
              }

              {estimationAgent.rendement_m2 &&
              <Col lg={12}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.averageYieldByPlant')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.rendement_m2}
                </div>
              </Col>
              }

              {estimationAgent.densite &&
              <Col lg={12}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.plantingDensity')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.densite}
                </div>
              </Col>
              }

              {estimationAgent.calcul_rendement_kg &&
              <Col lg={24}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.calculEstimateAverageYieldByPlant')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.calcul_rendement_kg}
                </div>
              </Col>
              }

              {estimationAgent.calcul_rendement_m2 &&
              <Col lg={12}>
                <div className="label-custom">
                  {i18n.t('createReport.estimationForecastsStep.calculEstimateAverageYield')} :
                </div>
                <div className="value-custom">
                  {estimationAgent.calcul_rendement_m2}
                </div>
              </Col>
              }
            </Row>
          </div>
        </div>
      ))}
    </ParcelleDetailSummaryStyles>
  );
};

ParcelleDetailSummary.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
};

export default ParcelleDetailSummary;
