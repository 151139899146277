import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isAuthenticated: false,
  currentUser: null,
};

const asyncAction = {
  reducer: () => {},
  prepare: payload => ({
    payload,
    meta: { async: true },
  }),
};

const getCurrentUserSuccess = (state, { payload }) => {
  state.isAuthenticated = true;
  state.currentUser = payload;
};

const logoutSuccess = state => {
  state.isAuthenticated = false;
  state.currentUser = null;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    login: {
      ...asyncAction,
    },
    signup: {
      ...asyncAction,
    },
    requestResetPassword: {
      ...asyncAction,
    },
    resetPassword: {
      ...asyncAction,
    },
    logout: {
      ...asyncAction,
    },
    getCurrentUser: {
      reducer: () => {},
      prepare: payload => ({
        payload,
        meta: { isOfflineMode: true, async: true },
      }),
    },
    getCurrentUserSuccess,
    logoutSuccess,
  },
});

export const { actions: authActions, reducer } = authSlice;

export default reducer;
