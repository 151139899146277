import axios from '../index';

export function getListActivitiesTypesApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/typesActivites`,
  });
}

export function getActivityTypeByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/typesActivites/${id}`,
  });
}

export function getListCulturesTypesApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/typesCultures`,
  });
}

export function getCultureTypeByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/typesCultures/${id}`,
  });
}

export function getListMunicipalitiesApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/communes`,
  });
}

export function getMunicipalitieByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/communes/${id}`,
  });
}

export function getListIslandsApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/iles`,
  });
}

export function getIleByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/iles/${id}`,
  });
}

export function getListMethodsOfCulturesApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/methodesCultures`,
  });
}

export function getMethodOfCultureByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/methodesCultures/${id}`,
  });
}

export function getListPlotsApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/parcelles`,
  });
}

export function getPlotByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/parcelles/${id}`,
  });
}

export function getListFarmersApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/agriculteurs`,
  });
}

export function getFarmerByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/agriculteurs/${id}`,
  });
}

export function getListProductsApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/produits`,
  });
}

export function getProductByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/produits/${id}`,
  });
}

export function getListSocialSecurityApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/typesRegimesSociaux`,
  });
}

export function getSocialSecurityByIdApi(id) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/typesRegimesSociaux/${id}`,
  });
}


