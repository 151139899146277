import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import setting from './setting';
import reports from './reports';
import offlineStack from './offlineStack';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    setting,
    reports,
    offlineStack,
  });
