const theme = {
  palette: {
    primaryColor: '#97C11F',
    secondaryColor: '#003750',
    backgroundColor: '#E0E9ED',
    loadingBackgroundColor: '#2c3e51cc',
    color: ['#788195', '#E4E6E9'],
    blue: '#2DA9E1',
    darkBlue: '#003750',
    greenBlue: '#1FC19C',
    red: '#D63A00',
  },
  fonts: {
    primary: 'Georgia, serif',
  },
  fontSize: {
    title: '35px',
    subtitle: '26px',
    normal: '20px',
    small: '16px',
  },
  items: {
    height: '60px',
  },
  icon: {
    normal: '20px',
    large: '30px',
  },
};

module.exports = theme;
