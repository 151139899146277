import React from 'react';
import PropTypes from 'prop-types';
// import components
import ReportDetailForm from 'containers/ReportDetail';

const ReportDetail = ({ match }) => {
  const {
    params: { reportId },
  } = match;

  return <ReportDetailForm id={reportId} />;
};

ReportDetail.propTypes = {
  match: PropTypes.object,
};

export default ReportDetail;
