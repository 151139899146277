import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { Button } from 'antd';
// import components
import ProductCard from 'components/ProductCard';
import Title from 'components/Title';
// import actions
import { actions } from 'redux/reports';
// import containers
import ParcelleDetailForm from './ParcelleDetailForm';
import { SummaryStyles } from './styles';

const Summary = ({ step, activeKey, newReportsToSync, onBack, onNext }) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const report = useSelector((state) => state.reports.currentNewReport);
  const currentNewReport = newReportsToSync || report;
  const dispatch = useDispatch();
  const submitReport = (e) => {
    e.preventDefault();

    if (newReportsToSync) {
      onNext();
      return;
    }
    setIsSubmiting(true);
    dispatch(actions.addNewReportToSync())
      .then(() => {
        setIsSubmiting(false);
        onNext();
      })
      .catch((e) => {
        console.log('e', e);
        setIsSubmiting(false);
      });
  };

  return (
    <SummaryStyles>
      <Title title={i18n.t('createReport.summaryStep.title', { step: 5 })} />
      <div className="content-section">
        <div className="title-summary">
          {i18n.t('createReport.summaryStep.estimatedMarketingTitle')}
        </div>
        <div className="list-product">
          {currentNewReport?.productsEstimatedFarmer?.map((product) => (
            <ProductCard product={product} />
          ))}
        </div>
      </div>

      <div className="content-section">
        <div className="title-summary">
          {i18n.t('createReport.summaryStep.agentEstimateTitle')}
        </div>

        {currentNewReport?.parcelles?.map((parcelle, index) =>
          activeKey === 5 ? (
            <ParcelleDetailForm data={parcelle} index={index} />
          ) : null,
        )}
      </div>

      <div className="action-section">
        <Button type="primary" className="cancel-btn" onClick={onBack}>
          {i18n.t('createReport.summaryStep.cancelBtn')}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmiting}
          onClick={submitReport}
        >
          {i18n.t('createReport.summaryStep.finishBtn')}
        </Button>
      </div>
    </SummaryStyles>
  );
};

Summary.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  newReportsToSync: PropTypes.object,
};

export default Summary;
