export async function uploadPhoto(fileBase64, publicId) {
  try {
    const res = await fetch(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          file: fileBase64,
          public_id: publicId,
          upload_preset: process.env.REACT_APP_CLOUD_PRESET,
        }),
      },
      {
        headers: {},
      },
    );
    const data = await res.json();
    return data;
  } catch (error) {
    return error;
  }
}
