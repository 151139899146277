import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { Button, Form } from 'antd';
import { isNumber, map, get, groupBy, flatten } from 'lodash';
// import actions
import { actions } from 'redux/reports';
// import components
import Title from 'components/Title';
// import containers
import ParcelleDetailForm from './ParcelleDetailForm';
import LastReportDrawer from './LastReportDrawer';
import { EstimationForecastsStyles } from './styles';

const EstimationForecastsAgent = ({
  newReportsToSync,
  form,
  onBack,
  onNext,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isShowLastReport, setIsShowLastReport] = useState(false);
  const [lastReport, setLastReport] = useState(null);
  const dispatch = useDispatch();

  const listFarmers = useSelector((state) => state.setting.listFarmers);
  const report = useSelector((state) => state.reports.currentNewReport);
  const currentNewReport = newReportsToSync || report;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  useEffect(() => {
    if (currentNewReport?.farmerId) {
      const farmer = listFarmers.find(
        (f) => f.id === currentNewReport?.farmerId,
      );
      setLastReport(farmer?.dernier_rapport[0] || null);
    } else {
      setLastReport(null);
    }
    // eslint-disable-next-line
  }, [currentNewReport]);

  const handleSubmit = (e, onNextPage = false) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (values.parcelles) {
          values.parcelles = map(
            values.parcelles,
            (parcelle, parcelleIndex) => {
              const currentParcelle = currentNewReport.parcelles[parcelleIndex];
              if (parcelle?.products) {
                // make it the sample add modal.
                // eslint-disable-next-line
                const _productGroups = groupBy(currentParcelle.products, 'nom');
                parcelle.products = Object.keys(_productGroups).map((key) =>
                  _productGroups[key].map((product, index) => ({
                    ...product,
                    ...get(
                      values,
                      `parcelles[${parcelleIndex}].products.${key}[${index}]`,
                    ),
                    estimatePlantingDate: get(
                      values,
                      `parcelles[${parcelleIndex}].products.${key}[${index}]`,
                    )?.estimatePlantingDate?.format('DD-MM-YYYY'),
                    estimateHarvestDate: get(
                      values,
                      `parcelles[${parcelleIndex}].products.${key}[${index}]`,
                    )?.estimateHarvestDate?.format('DD-MM-YYYY'),
                  })),
                );
                parcelle.products = flatten(parcelle.products);

                return { ...currentParcelle, ...parcelle };
              }
              return currentParcelle;
            },
          );

          if (newReportsToSync) {
            dispatch(
              actions.updateNewReportToSync({ ...newReportsToSync, ...values }),
            );

            onNextPage ? onNext() : onBack();
          }

          setIsSubmiting(true);
          dispatch(actions.addNewReport(values))
            .then(() => {
              setIsSubmiting(false);
              onNextPage ? onNext() : onBack();
            })
            .catch(() => {
              setIsSubmiting(false);
            });
        } else {
          onNextPage ? onNext() : onBack();
        }
      } else if (!onNextPage) {
        onBack();
      }
    });
  };

  const onRemoveProductOfParcelle = (parcelleIndex, products) => {
    if (isNumber(parcelleIndex)) {
      const parcelles = currentNewReport?.parcelles?.map(
        (parcelle, parIndex) => {
          if (parcelle && parIndex === parcelleIndex) {
            return {
              ...parcelle,
              products,
            };
          }
          return parcelle;
        },
      );
      if (newReportsToSync) {
        dispatch(
          actions.updateNewReportToSync({ ...newReportsToSync, parcelles }),
        );
      } else {
        dispatch(
          actions.addNewReport({
            ...currentNewReport,
            parcelles,
          }),
        );
      }
    }
  };

  return (
    <EstimationForecastsStyles>
      <Title
        title={i18n.t('createReport.estimationForecastsStep.title', {
          step: 4,
        })}
        subtitle={i18n.t('createReport.estimationForecastsStep.subTitle')}
      />
      <div className="content-section">
        {currentNewReport?.parcelles?.map((parcelle, index) => (
          <ParcelleDetailForm
            data={parcelle}
            index={index}
            getFieldValue={getFieldValue}
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            handleSubmit={handleSubmit}
            onRemove={onRemoveProductOfParcelle}
            isLastReport={!!lastReport}
            onShowLastReport={() => setIsShowLastReport(true)}
          />
        ))}
        <div className="action-section">
          <Button
            type="primary"
            className="cancel-btn"
            onClick={(e) => handleSubmit(e)}
            loading={isSubmiting}
          >
            {i18n.t('createReport.cancelBtn')}
          </Button>
          <Button
            type="primary"
            onClick={(e) => handleSubmit(e, true)}
            loading={isSubmiting}
          >
            {i18n.t('createReport.nextBtn')}
          </Button>
        </div>
      </div>
      <LastReportDrawer
        visible={isShowLastReport}
        onClose={() => setIsShowLastReport(false)}
        currentReport={lastReport}
      />
    </EstimationForecastsStyles>
  );
};

EstimationForecastsAgent.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  form: PropTypes.object,
  newReportsToSync: PropTypes.object,
};

export default Form.create({ name: 'estimation_forecast_step' })(
  EstimationForecastsAgent,
);
