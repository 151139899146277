import { useSelector } from 'react-redux';
import { isArray } from 'lodash';

export default (fieldName, id) => {
  if (!fieldName || !id) return null;
  const listData = useSelector(state => state.setting[fieldName]);
  if (isArray(id)) {
    const data = listData?.filter(d => id.indexOf(d.id) !== -1);
    return data?.map(d => d.nom).join(', ');
  }
  const data = listData?.find(d => d.id === id);
  return data?.nom;
};
