import { omit } from 'lodash';
import { actions } from 'redux/offlineStack/index';

export default store => next => action => {
  const { isOffline } = store.getState().setting;
  if (action?.meta?.isOfflineMode && isOffline) {
    // eslint-disable-next-line
    action?.deferred && action.deferred.resolve();
    store.dispatch(actions.addOfflineActions(omit(action, 'deferred')));
    return '';
  }
  return next(action);
};

export const afterReloadOfflineMiddleware = store => next => action => {
  const { offlineActions } = store.getState().offlineStack;
  if (offlineActions.find(e => e.type === action?.type)) {
    store.dispatch(actions.removeOfflineActions(action));
    return '';
  }
  return next(action);
};
