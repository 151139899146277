import React from 'react';
import { useSelector } from 'react-redux';
import { find, groupBy } from 'lodash';
import PropTypes from 'prop-types';
import i18n from 'i18next';
// import components
import ProductCard from 'components/ProductCard';
import Title from 'components/Title';
import GoBackButton from 'components/GoBackButton';
// import containers
import { useParams } from 'react-router';
import ParcelleDetailSummary from './ParcelleDetailSummary';
import ReportDetailStyles from './styles';

const ReportDetail = () => {
  const { reportId } = useParams();
  const currentReport = useSelector(
    state =>
      find(state.reports.listReports, report => {
        return `${report.id}` === reportId;
      }) ||
      find(state.reports.listNewReportsToSync, report => {
        return `${report.id}` === reportId;
      }),
  );

  const estimationsAgents = groupBy(
    currentReport.estimations_agents,
    'parcelle.id',
  );

  const rebuildEstimationsAgents = Object.keys(estimationsAgents).map(
    (data, index) => {
      return {
        ...estimationsAgents[data][0].parcelle,
        estimations_agents: estimationsAgents[data],
      };
    },
  );

  return (
    <ReportDetailStyles>
      <Title
        title={`Agriculteur : ${currentReport?.agriculteur.nom} ${currentReport?.agriculteur.prenom}`}
      />
      <div className="content-section">
        <div className="title-summary">
          {i18n.t('createReport.summaryStep.estimatedMarketingTitle')}
        </div>
        <div className="list-product">
          {currentReport?.estimations_agriculteurs?.map(data => (
            <ProductCard product={data?.produit} quantity={data?.quantite_kg} />
          ))}
        </div>
      </div>

      <div className="content-section">
        <div className="title-summary">
          {i18n.t('createReport.summaryStep.agentEstimateTitle')}
        </div>
        {rebuildEstimationsAgents?.map((data, index) => (
          <ParcelleDetailSummary
            data={data}
            index={index}
            estimations_agents={estimationsAgents}
          />
        ))}
      </div>

      <GoBackButton />
    </ReportDetailStyles>
  );
};

ReportDetail.propTypes = {
  id: PropTypes.string,
};

export default ReportDetail;
