import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { Button, Form, InputNumber, Icon, Input } from 'antd';
import { filter, groupBy, flatten, get, flatMap } from 'lodash';
import theme from 'configs/theme';
// import actions
import { actions } from 'redux/reports';
// import components
import Title from 'components/Title';
// import containers
import AddProductPopup from './AddProductPopup';
import LastReportDrawer from './LastReportDrawer';
import { EstimatedMarketingStyles } from './styles';

const EstimationForecastsFarmer = ({
  newReportsToSync,
  form,
  onBack,
  onNext,
  step,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isShowAddProductPopup, setIsShowAddProductPopup] = useState(false);
  const [isShowLastReport, setIsShowLastReport] = useState(false);
  const [lastReport, setLastReport] = useState(null);
  const dispatch = useDispatch();
  const listFarmers = useSelector((state) => state.setting.listFarmers);
  const report = useSelector((state) => state.reports.currentNewReport);
  const currentNewReport = newReportsToSync || report;
  const { getFieldDecorator } = form;
  const [productGroups, setWorkGroup] = useState(
    groupBy(currentNewReport?.productsEstimatedFarmer, 'nom'),
  );

  useEffect(() => {
    if (
      currentNewReport?.productsEstimatedFarmer?.length !==
      flatten(productGroups)?.length
    ) {
      setWorkGroup(groupBy(currentNewReport?.productsEstimatedFarmer, 'nom'));
    }
    // eslint-disable-next-line
  }, [currentNewReport]);

  useEffect(() => {
    if (currentNewReport?.farmerId) {
      const farmer = listFarmers.find(
        (f) => f.id === currentNewReport?.farmerId,
      );
      setLastReport(farmer?.dernier_rapport[0] || null);
    } else {
      setLastReport(null);
    }
    // eslint-disable-next-line
  }, [currentNewReport]);

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        // make it the sample add modal.
        // eslint-disable-next-line
        const _productGroups = groupBy(
          currentNewReport.productsEstimatedFarmer,
          'nom',
        );
        let productsEstimatedFarmer = Object.keys(_productGroups).map((key) =>
          _productGroups[key].map((e, index) => ({
            ...e,
            ...get(values, `products.${key}[${index}]`),
          })),
        );

        productsEstimatedFarmer = flatten(productsEstimatedFarmer);

        if (newReportsToSync) {
          dispatch(
            actions.updateNewReportToSync({
              ...newReportsToSync,
              productsEstimatedFarmer,
            }),
          );
          onNext();
          return;
        }
        setIsSubmiting(true);
        dispatch(actions.addNewReport({ productsEstimatedFarmer }))
          .then(() => {
            setIsSubmiting(false);
            onNext();
          })
          .catch((error) => {
            console.log('error', error);
            setIsSubmiting(false);
          });
      }
    });
  };

  const onRemoveProduct = (nom, index) => () => {
    if (
      currentNewReport?.productsEstimatedFarmer &&
      nom &&
      (index === 0 || index)
    ) {
      const newProductGroups = {
        ...productGroups,
        [nom]: filter(productGroups[nom], (product, i) => {
          return i !== index;
        }),
      };

      dispatch(
        actions.addNewReport({
          ...currentNewReport,
          productsEstimatedFarmer: flatMap(newProductGroups),
        }),
      );
    }
  };

  const renderNoProduct = () => (
    <div className="no-product">{i18n.t('createReport.noProduct')}</div>
  );

  const renderListProducts = () => (
    <div className="list-product">
      <Form layout="vertical">
        {Object.keys(productGroups).map((key) =>
          productGroups[key].map((product, index) => (
            <div className="product-section" key={`${product.id}-${index}`}>
              <Icon
                type="close-circle"
                theme="twoTone"
                twoToneColor={theme.palette.red}
                className="remove-product-icon"
                onClick={onRemoveProduct(product.nom, index)}
              />
              <img
                src={`${process.env.REACT_APP_CLOUDINARY_URL}/w_400,h_400,c_crop,g_face,r_max/w_100/${product.photo}`}
                alt={product.nom}
                title={product.nom}
              />
              <div className="name">{product.nom}</div>

              <div className="quantity-title">
                {i18n.t('createReport.summaryStep.quantity', { step })}
              </div>

              <div className="quantity-info">
                <Form.Item>
                  {getFieldDecorator(
                    `products.${product.nom}[${index}].estimateAverageYield`,
                    {
                      rules: [
                        { required: true, message: 'Ce champ est requis' },
                      ],
                      initialValue: product?.estimateAverageYield,
                    },
                  )(<InputNumber min={1} />)}
                </Form.Item>
              </div>

              <div className="quantity-title">
                Prix de ventes previsionnels distributeurs (en FCFP)
              </div>

              <div className="quantity-info">
                <Form.Item>
                  {getFieldDecorator(
                    `products.${product.nom}[${index}].priceDistributors`,
                    {
                      initialValue: product?.priceDistributors || 0,
                    },
                  )(<InputNumber min={0} />)}
                </Form.Item>
              </div>

              <div className="quantity-title">
                Prix de ventes previsionnels grossistes (en FCFP)
              </div>

              <div className="quantity-info">
                <Form.Item>
                  {getFieldDecorator(
                    `products.${product.nom}[${index}].wholesalers`,
                    {
                      initialValue: product?.wholesalers || 0,
                    },
                  )(<InputNumber min={0} />)}
                </Form.Item>
              </div>

              <div className="quantity-title">Lieu de vente</div>

              <div className="quantity-info">
                <Form.Item>
                  {getFieldDecorator(
                    `products.${product.nom}[${index}].placeOfSale`,
                    {
                      initialValue: product?.placeOfSale || '',
                    },
                  )(<Input />)}
                </Form.Item>
              </div>
            </div>
          )),
        )}
      </Form>
    </div>
  );

  return (
    <EstimatedMarketingStyles>
      <Title
        title={i18n.t('createReport.estimatedMarketingStep.title', { step: 3 })}
        subtitle={i18n.t('createReport.estimatedMarketingStep.subTitle')}
      />
      <div className="form-content">
        {!currentNewReport?.productsEstimatedFarmer?.length &&
          renderNoProduct()}
        {!!currentNewReport?.productsEstimatedFarmer?.length &&
          renderListProducts()}
        <div className="list-actions-section">
          <div className="action-section">
            {lastReport && (
              <Button
                type="primary"
                className="blue-btn"
                onClick={() => setIsShowLastReport(true)}
              >
                {i18n.t(
                  'createReport.estimatedMarketingStep.getDataFromPreviousMonthBtn',
                )}
              </Button>
            )}

            <Button
              type="primary"
              className="blue-btn"
              onClick={() => setIsShowAddProductPopup(true)}
            >
              {i18n.t(
                'createReport.estimatedMarketingStep.addProductPopup.title',
              )}
            </Button>
          </div>
          <div className="action-section">
            <Button type="primary" className="cancel-btn" onClick={onBack}>
              {i18n.t('createReport.cancelBtn')}
            </Button>
            <Button type="primary" onClick={handleSubmit} loading={isSubmiting}>
              {i18n.t('createReport.nextBtn')}
            </Button>
          </div>
        </div>
      </div>
      <AddProductPopup
        onClose={() => setIsShowAddProductPopup(false)}
        visible={isShowAddProductPopup}
      />
      <LastReportDrawer
        visible={isShowLastReport}
        onClose={() => setIsShowLastReport(false)}
        currentReport={lastReport}
      />
    </EstimatedMarketingStyles>
  );
};

EstimationForecastsFarmer.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  form: PropTypes.object,
  newReportsToSync: PropTypes.object,
  step: PropTypes.number,
};

export default Form.create({ name: 'estimated_marketing_step' })(
  EstimationForecastsFarmer,
);
