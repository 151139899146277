import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { Form, Button, InputNumber, Select } from 'antd';
import moment from 'moment';
// import components
import Title from 'components/Title';
// import actions
import { actions } from 'redux/reports';
import { ForecastPeriodStepStyles } from './styles';

const { Option } = Select;

const ForecastPeriodStep = ({
  step,
  newReportsToSync,
  form,
  onBack,
  onNext,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const dispatch = useDispatch();
  const report = useSelector(state => state.reports.currentNewReport);
  const currentNewReport = newReportsToSync || report;
  const { getFieldDecorator } = form;

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (newReportsToSync) {
          dispatch(
            actions.updateNewReportToSync({ ...newReportsToSync, ...values }),
          );
          onNext();
          return;
        }
        setIsSubmiting(true);
        dispatch(actions.addNewReport(values))
          .then(() => {
            setIsSubmiting(false);
            onNext();
          })
          .catch(() => {
            setIsSubmiting(false);
          });
      }
    });
  };

  return (
    <ForecastPeriodStepStyles>
      <Title
        title={i18n.t('createReport.forecastPeriodTitle', { step })}
        subtitle={i18n.t('createReport.forecastPeriodSubTitle')}
      />
      <div className="form-content">
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label={i18n.t('createReport.month')}>
            {getFieldDecorator('forecastMonth', {
              rules: [{ required: true }],
              initialValue:
                currentNewReport?.forecastMonth || moment().format('M'),
            })(
              <Select>
                <Option value="1">Janvier</Option>
                <Option value="2">Février</Option>
                <Option value="3">Mars</Option>
                <Option value="4">Avril</Option>
                <Option value="5">Mai</Option>
                <Option value="6">Juin</Option>
                <Option value="7">Juillet</Option>
                <Option value="8">Aout</Option>
                <Option value="9">Septembre</Option>
                <Option value="10">Octobre</Option>
                <Option value="11">Novembre</Option>
                <Option value="12">Décembre</Option>
              </Select>,
            )}
          </Form.Item>
          <Form.Item label={i18n.t('createReport.year')}>
            {getFieldDecorator('forecastYear', {
              rules: [{ required: true }],
              initialValue:
                currentNewReport?.forecastYear || moment().format('YYYY'),
            })(<InputNumber min={1970} />)}
          </Form.Item>
        </Form>
        <div className="action-section">
          <Button type="primary" className="cancel-btn" onClick={onBack}>
            {i18n.t('createReport.cancelBtn')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmiting}
            onClick={handleSubmit}
          >
            {i18n.t('createReport.nextBtn')}
          </Button>
        </div>
      </div>
    </ForecastPeriodStepStyles>
  );
};

ForecastPeriodStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  form: PropTypes.object,
  newReportsToSync: PropTypes.object,
};

export default Form.create({ name: 'forecast_period_step' })(
  ForecastPeriodStep,
);
