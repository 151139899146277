import styled from 'styled-components';
import theme from 'configs/theme';

export default styled.div`
  .report-card {
    width: 250px;
    margin: 15px 10px;
    text-align: center;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
    color: ${theme.palette.darkBlue};
    position: relative;
    background-color: #fff;

    .status-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 15px;
      right: 15px;

      &.success {
        background-color: ${theme.palette.primaryColor};
      }

      &.error {
        background-color: ${theme.palette.red};
      }
    }

    .ant-divider {
      background-color: ${theme.palette.darkBlue};
      height: 1.2em;
      transform: rotate(15deg);
      margin: 0 5px;
    }

    .tit {
      color: ${theme.palette.color[0]};
      font-style: italic;
      font-size: ${theme.fontSize.small};
    }

    .name {
      font-size: 21px;
    }
    .date {
      font-size: ${theme.fontSize.small};
    }
  }
`;
