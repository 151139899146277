import axios from '../index';

export function loginApi(paramsReq) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SERVER_URL}/auth/login`,
    data: paramsReq,
  });
}

export function signupApi(paramsReq) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SERVER_URL}/auth/signup`,
    data: paramsReq,
  });
}

export function logoutApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/auth/logout`,
  });
}

export function getCurrentUserApi() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/auth/user`,
  });
}

export function requestResetPasswordApi(paramsReq) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SERVER_URL}/password/create`,
    data: paramsReq,
  });
}

export function resetPasswordApi(paramsReq) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SERVER_URL}/password/reset`,
    data: paramsReq,
  });
}
