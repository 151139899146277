import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isOffline: !window.navigator.onLine,
  listActivitiesTypes: null,
  listCulturesTypes: null,
  listCategoriesOfProduct: null,
  listMunicipalities: null,
  listIslands: null,
  listMethodsOfCultures: null,
  listPlots: null,
  listFarmers: null,
  listProducts: null,
  listSocialSecurity: null,
  listNewFarmers: [],
};

const setOnline = state => {
  state.isOffline = false;
};

const setOffline = state => {
  state.isOffline = true;
};

const getListActivitiesTypesSuccess = (state, { payload }) => {
  state.listActivitiesTypes = payload;
};

const getListCulturesTypesSuccess = (state, { payload }) => {
  state.listCulturesTypes = payload;
};

const getListCategoriesOfProductSuccess = (state, { payload }) => {
  state.listCategoriesOfProduct = payload;
};

const getListMunicipalitiesSuccess = (state, { payload }) => {
  state.listMunicipalities = payload;
};

const getListIslandsSuccess = (state, { payload }) => {
  state.listIslands = payload;
};

const getListMethodsOfCulturesSuccess = (state, { payload }) => {
  state.listMethodsOfCultures = payload;
};

const getListPlotsSuccess = (state, { payload }) => {
  state.listPlots = payload;
};

const getListFarmersSuccess = (state, { payload }) => {
  state.listFarmers = payload;
};

const getListProductsSuccess = (state, { payload }) => {
  state.listProducts = payload;
};

const getListSocialSecuritySuccess = (state, { payload }) => {
  state.listSocialSecurity = payload;
};

const setListNewFarmers = (state, { payload }) => {
  state.listFarmers = state.listNewFarmers
    ? [...state.listFarmers, payload]
    : [payload];
};

const clearListNewFarmers = state => {
  state.listNewFarmers = [];
};

const settingSlice = createSlice({
  name: 'setting',
  initialState: INITIAL_STATE,
  reducers: {
    fetchAllSettings: {
      reducer: () => {},
      prepare: payload => ({
        payload,
        meta: { isOfflineMode: true },
      }),
    },
    getListActivitiesTypes: () => {},
    getListCulturesTypes: () => {},
    getListCategoriesOfProduct: () => {},
    getListMunicipalities: () => {},
    getListIslands: () => {},
    getListMethodsOfCultures: () => {},
    getListPlots: () => {},
    getListFarmers: () => {},
    getListProducts: () => {},
    getListSocialSecurity: () => {},
    setOnline,
    setOffline,
    getListActivitiesTypesSuccess,
    getListCulturesTypesSuccess,
    getListCategoriesOfProductSuccess,
    getListMunicipalitiesSuccess,
    getListIslandsSuccess,
    getListMethodsOfCulturesSuccess,
    getListPlotsSuccess,
    getListFarmersSuccess,
    getListProductsSuccess,
    getListSocialSecuritySuccess,
    setListNewFarmers,
    clearListNewFarmers,
  },
});

export const { actions: settingActions, reducer } = settingSlice;

export default reducer;
