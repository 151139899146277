// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { Button, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import actions
import { actions } from 'redux/reports';
// import components
import UserInfoCard from 'components/UserInfo';
import FarmerInfoCard from 'components/FarmerInfoCard';
import Title from 'components/Title';
// import containers
import DashboardWrapper from './styles';

const Dashboard = () => {
  const dispatch = useDispatch();
  const listFarmers = useSelector(state => state.setting.listFarmers);
  const listNewReportsToSync = useSelector(
    state => state.reports.listNewReportsToSync,
  );
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    dispatch(actions.getAllReport());
    // eslint-disable-next-line
  }, []);

  const syncReport = () => {
    dispatch(actions.sendListNewReportsToSync())
      .then(() => {
        notification.info({
          message: 'Synchronisation éffectué !',
        });
      })
      .catch(() => {
        notification.info({
          message:
            'Vous devez être connecté à Internet pour synchroniser vos rapports !',
        });
      });
  };

  return (
    <DashboardWrapper>
      {listNewReportsToSync.length > 0 && (
        <Title title={i18n.t('homepage.toSync')} />
      )}
      {listNewReportsToSync && (
        <div className="content-section">
          {listNewReportsToSync.map(data => (
            <UserInfoCard dataToSync={data} />
          ))}
        </div>
      )}

      <Title title={i18n.t('homepage.farmerList')} />
      <div className="content-section">
        {listFarmers?.map(data => (
          <FarmerInfoCard data={data} />
        ))}
      </div>

      <div className="action-section">
        <Link to="/create-new-report">
          <Button type="primary">{i18n.t('createReport.titleBar')}</Button>
        </Link>

        <Button type="primary" className="sync-report-btn" onClick={syncReport}>
          {i18n.t('syncReport.titleBar')}
        </Button>
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard;
