import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import pages
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';

const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
];

const PublicRoutes = () => {
  return (
    <Switch>
      {routes.map(route => {
        return <Route {...route} key={route.path} />;
      })}
    </Switch>
  );
};

export default PublicRoutes;
