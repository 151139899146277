import axios from 'axios';
import Cookies from 'js-cookie';

if (Cookies.get('authToken')) {
  axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
    'authToken',
  )}`;
}

export const setAuthToken = token => {
  if (token) {
    Cookies.set('authToken', token, { expires: 1 });
    axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
      'authToken',
    )}`;
  } else {
    Cookies.remove('authToken');
    axios.defaults.headers.common.Authorization = '';
  }
};

export default axios;
