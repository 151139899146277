import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { flatMap, map } from 'lodash';
// import layout
import PrivateLayout from 'layout/PrivateLayout';
// import components
import NotFoundPage from 'components/404Page';
// import pages
import Home from 'pages/Dashboard';
import ListReportOfFarmer from 'pages/ListReportOfFarmer';
import CreateNewReport from 'pages/CreateNewReport';
import ReportDetail from 'pages/ReportDetail';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/farmers/:farmerId',
    component: ListReportOfFarmer,
  },
  {
    path: '/create-new-report',
    component: CreateNewReport,
  },
  {
    path: '/reports/:reportId',
    component: ReportDetail,
  },
  {
    path: '/sync-reports/:offlineId',
    component: CreateNewReport,
  },
];

const PrivateRoutes = () => (
  <PrivateLayout>
    <Switch>
      {map(
        flatMap(routes, route => {
          if (route.routes) {
            return map(route.routes, subRoute => ({
              ...subRoute,
              path: route.path + subRoute.path,
              exact: subRoute.path === '/',
            }));
          }
          return route;
        }),
        route => (
          <Route {...route} key={route.path} />
        ),
      )}
      <Route component={NotFoundPage} />
    </Switch>
  </PrivateLayout>
);

export default PrivateRoutes;
