import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import {
  Button,
  Form,
  InputNumber,
  DatePicker,
  Icon,
  Row,
  Col,
  Select,
} from 'antd';
import moment from 'moment';
import theme from 'configs/theme';
import frLocale from 'antd/es/date-picker/locale/fr_FR';

// import hooks
import useNomFromSetting from 'customHooks/useNomFromSetting';
// import containers
import { flatten, groupBy, filter, flatMap, pick } from 'lodash';
import AddProductForParcellesPopup from './AddProductForParcellesPopup';
import { ParcelleDetailFormStyles } from './styles';
import UploadImagePWA from '../UploadImagePWA';

const { Option } = Select;

const ParcelleDetailForm = ({
  data,
  getFieldDecorator,
  getFieldValue,
  setFieldsValue,
  index,
  onRemove,
  isLastReport = false,
  onShowLastReport,
  handleSubmit,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isComplexCalul, setIsComplexCalcul] = useState({});
  const [selectedParcelleIndex, setSelectedParcelleIndex] = useState(-1);
  const farmerIsland = useNomFromSetting('listIslands', data?.farmerIsland);
  const [productGroups, setWorkGroup] = useState(
    groupBy(data?.products, 'nom'),
  );

  useEffect(() => {
    if (data?.products?.length !== flatten(productGroups)?.length) {
      setWorkGroup(groupBy(data?.products, 'nom'));
    }
    if (Object.keys(isComplexCalul).length === 0 && data?.products?.length) {
      const temp = {};
      const groupProducts = groupBy(data?.products, 'nom');
      Object.keys(groupBy(data?.products, 'nom')).forEach((key) =>
        groupProducts[key].map((e, i) => {
          temp[`parcelles[${index}].products.${key}[${i}]`] =
            e.methodCalcul !== undefined ? e.methodCalcul : 'false';
        }),
      );
      setIsComplexCalcul(temp);
    }
    // eslint-disable-next-line
  }, [data]);

  const farmerMunicipality = useNomFromSetting(
    'listMunicipalities',
    data?.farmerMunicipality,
  );

  const farmerCultureMethod = useNomFromSetting(
    'listMethodsOfCultures',
    data?.farmerCultureMethod,
  );

  const farmerTypeActivity = useNomFromSetting(
    'listActivitiesTypes',
    data?.farmerTypeActivity,
  );

  const farmerTypeCulture = useNomFromSetting(
    'listCulturesTypes',
    data?.farmerTypeCulture,
  );

  const onShowAddProductPopup = (parcelleIndex) => () => {
    setSelectedParcelleIndex(parcelleIndex);
    setIsVisible(true);
  };

  const onRemoveProduct = (nom, productIndex) => () => {
    const newProductGroups = {
      ...productGroups,
      [nom]: filter(productGroups[nom], (product, i) => {
        return i !== productIndex;
      }),
    };
    onRemove(index, flatMap(newProductGroups));
  };

  const onChangeCalcul = (key) => (value) => {
    value === 'true'
      ? setIsComplexCalcul({ ...isComplexCalul, [key]: 'true' })
      : setIsComplexCalcul({ ...isComplexCalul, [key]: 'false' });
    setFieldsValue({
      [`${key}.methodCalcul`]: value,
    });
  };

  const getCalculRaw = (productKey) => {
    const tempData = {
      estimateProductionStatus:
        getFieldValue(`${productKey}.estimateProductionStatus`) || 0,
      calculRawEstimate: getFieldValue(`${productKey}.calculRawEstimate`) || 0,
    };

    if (tempData.estimateProductionStatus > 0) {
      setFieldsValue({
        [`${productKey}.calculRawEstimateTotal`]:
          tempData.calculRawEstimate -
          (tempData.calculRawEstimate * tempData.estimateProductionStatus) / 100,
      });
    } else {
      setFieldsValue({
        [`${productKey}.calculRawEstimateTotal`]: tempData.calculRawEstimate,
      });
    }
  };

  const handleCalculComplexe = (productKey) => {
    const tempData = {
      A: getFieldValue(`${productKey}.estimatePlantedArea`) || 0,
      B: getFieldValue(`${productKey}.numberPlant`) || 0,
      C: getFieldValue(`${productKey}.estimatePlantingDensity`) || 0,
      D: getFieldValue(`${productKey}.estimateProductionStatus`) || 0,
      E: getFieldValue(`${productKey}.estimateAverageYield`) || 0,
      F: getFieldValue(`${productKey}.estimateAverageYieldByPlant`) || 0,
    };

    if (tempData.A > 0 && tempData.E > 0 && tempData.D > 0) {
      setFieldsValue({
        [`${productKey}.calculEstimateAverageYield`]:
          tempData.E * tempData.A -
          (tempData.D / 100) * (tempData.E * tempData.A),
      });
    }

    if (tempData.F > 0 && tempData.B > 0 && tempData.D > 0) {
      setFieldsValue({
        [`${productKey}.calculEstimateAverageYieldByPlant`]:
          tempData.F * tempData.B -
          (tempData.D / 100) * (tempData.F * tempData.B),
      });
    }

    if (tempData.F > 0 && tempData.C > 0 && tempData.A > 0 && tempData.D > 0) {
      setFieldsValue({
        [`${productKey}.calculEstimateAverageYieldByDensity`]:
          tempData.F * (tempData.C * tempData.A) -
          (tempData.D / 100) * (tempData.F * (tempData.C * tempData.A)),
      });
    }
  };

  return (
    <ParcelleDetailFormStyles>
      <div className="title-div">
        <div className="title">
          {`${i18n.t('createReport.estimationForecastsStep.parcel')} situé à :`}
        </div>
        <div className="subTitle">
          <div className="info-section">
            <div className="info">
              {`${farmerIsland} / ${farmerMunicipality} / ${farmerCultureMethod} / ${farmerTypeActivity} / ${farmerTypeCulture} / ${data.farmerPlotArea} m2`}
            </div>
          </div>
          {getFieldDecorator && (
            <div className="action-section">
              {isLastReport && (
                <Button
                  type="primary"
                  size="large"
                  className="blue-btn"
                  onClick={onShowLastReport}
                >
                  {i18n.t('createReport.getDataFromPreviousMonthBtn')}
                </Button>
              )}

              <Button
                type="primary"
                size="large"
                className="blue-btn"
                onClick={onShowAddProductPopup(index)}
              >
                {i18n.t('createReport.addProductBtn')}
              </Button>
            </div>
          )}
        </div>
      </div>

      {(!data?.products?.length || data?.products?.length === 0) && (
        <div className="no-product">{i18n.t('createReport.noProduct')}</div>
      )}
      {Object.keys(productGroups).map((key) =>
        productGroups[key].map((product, i) => (
          <div className="product-form">
            <UploadImagePWA
              dataKey={`parcelles[${index}].products.${key}[${i}].photo`}
            />
            <div className="img-section">
              <img
                src={
                  localStorage.getItem(product.photo) ||
                  `${process.env.REACT_APP_CLOUDINARY_URL}/w_400,h_400,c_crop,g_face,r_max/w_100/${product.photo}`
                }
                alt={product.nom}
                title={product.nom}
              />
              <div className="name">{product.nom}</div>
            </div>
            <Icon
              type="close-circle"
              theme="twoTone"
              twoToneColor={theme.palette.red}
              className="remove-product-icon"
              onClick={onRemoveProduct(product.nom, i)}
            />
            <div className="form-section">
              <Form layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col lg={24}>
                    <div className="title">Informations générales</div>
                  </Col>

                  <Col lg={12}>
                    <Form.Item
                      className="detail"
                      label={i18n.t(
                        'createReport.estimationForecastsStep.plantingDate',
                      )}
                    >
                      {getFieldDecorator &&
                        getFieldDecorator(
                          `parcelles[${index}].products.${key}[${i}].estimatePlantingDate`,
                          {
                            initialValue:
                              product?.estimatePlantingDate &&
                              moment(
                                product?.estimatePlantingDate,
                                'DD-MM-YYYY',
                              ),
                          },
                        )(
                          <DatePicker locale={frLocale} format="DD MMM YYYY" />,
                        )}
                      {!getFieldDecorator && (
                        <DatePicker
                          locale={frLocale}
                          format="DD MMM YYYY"
                          disabled
                          value={moment(
                            product?.estimatePlantingDate,
                            'DD-MM-YYYY',
                          )}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col lg={12}>
                    <Form.Item
                      className="detail"
                      label={i18n.t(
                        'createReport.estimationForecastsStep.estimatedHarvestDate',
                      )}
                    >
                      {getFieldDecorator &&
                        getFieldDecorator(
                          `parcelles[${index}].products.${key}[${i}].estimateHarvestDate`,
                          {
                            initialValue:
                              product?.estimateHarvestDate &&
                              moment(
                                product?.estimateHarvestDate,
                                'DD-MM-YYYY',
                              ),
                          },
                        )(
                          <DatePicker locale={frLocale} format="DD MMM YYYY" />,
                        )}
                      {!getFieldDecorator && (
                        <DatePicker
                          locale={frLocale}
                          format="DD MMM YYYY"
                          disabled
                          value={moment(
                            product?.estimateHarvestDate,
                            'DD-MM-YYYY',
                          )}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <div className="title">Méthodes de calcul</div>
                  </Col>

                  {getFieldDecorator &&
                    getFieldDecorator(
                      `parcelles[${index}].products.${key}[${i}].methodCalcul`,
                      {
                        initialValue:
                          isComplexCalul[
                            `parcelles[${index}].products.${key}[${i}]`
                          ],
                      },
                    )(
                      <Select
                        onChange={onChangeCalcul(
                          `parcelles[${index}].products.${key}[${i}]`,
                        )}
                      >
                        <Option value="false">Calcul simplifié</Option>
                        <Option value="true">Calcul complexe</Option>
                      </Select>,
                    )}

                  {!getFieldDecorator && (
                    <Select
                      value={
                        isComplexCalul[
                          `parcelles[${index}].products.${key}[${i}]`
                        ]
                      }
                    >
                      <Option value="false">Calcul simplifié</Option>
                      <Option value="true">Calcul complexe</Option>
                    </Select>
                  )}

                  {isComplexCalul[
                    `parcelles[${index}].products.${key}[${i}]`
                  ] === 'false' && (
                    <div>
                      <Col lg={24}>
                        <p>
                          Vous pouvez estimer le rendement sans calcul,
                          completez directement les cases si dessous:
                        </p>
                      </Col>

                      <Col lg={24}>
                        <Form.Item
                          className="detail"
                          label={`${i18n.t(
                            'createReport.estimationForecastsStep.productionStatus',
                          )}`}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].estimateProductionStatus`,
                              {
                                initialValue: product?.estimateProductionStatus,
                              },
                            )(<InputNumber min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              disabled
                              value={product?.estimateProductionStatus}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={24}>
                        <Form.Item
                          className="detail"
                          label={i18n.t(
                            'createReport.estimationForecastsStep.calculRawEstimate',
                          )}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].calculRawEstimate`,
                              {
                                initialValue: product?.calculRawEstimate,
                              },
                            )(<InputNumber min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              min={0}
                              disabled
                              value={product?.calculRawEstimate}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={24}>
                        <div className="title">Résultats</div>
                      </Col>

                      <Col lg={24}>
                        <Form.Item
                          className="detail"
                          label={i18n.t(
                            'createReport.estimationForecastsStep.calculRawEstimateTotal',
                          )}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].calculRawEstimateTotal`,
                              {
                                initialValue: product?.calculRawEstimateTotal,
                              },
                            )(<InputNumber disabled min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              min={0}
                              disabled
                              value={product?.calculRawEstimateTotal}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={24}>
                        <Button
                          type="primary"
                          onClick={() =>
                            getCalculRaw(
                              `parcelles[${index}].products.${key}[${i}]`,
                            )
                          }
                        >
                          Calculer
                        </Button>
                      </Col>
                    </div>
                  )}

                  {isComplexCalul[
                    `parcelles[${index}].products.${key}[${i}]`
                  ] === 'true' && (
                    <div>
                      <Col lg={24}>
                        <p>
                          Si vous souhaitez calculer le rendement avec la
                          tablette, remplissez pour:
                          <ul>
                            <li>
                              un calcul de rendement avec la surface: les cases
                              a, d, e
                            </li>

                            <li>
                              un calcul de rendement avec le nombre de plant:
                              les cases b, d, f
                            </li>

                            <li>
                              un calcul de rendement avec la densité de
                              plantation: les cases a, c, d, f
                            </li>
                          </ul>
                        </p>
                      </Col>

                      <Col lg={12}>
                        <Form.Item
                          className="detail"
                          label={`a) ${i18n.t(
                            'createReport.estimationForecastsStep.plantedArea',
                          )}`}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].estimatePlantedArea`,
                              {
                                initialValue: product?.estimatePlantedArea,
                              },
                            )(<InputNumber min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              disabled
                              value={product?.estimatePlantedArea}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={12}>
                        <Form.Item
                          className="detail"
                          label={`b) ${i18n.t(
                            'createReport.estimationForecastsStep.numberPlant',
                          )}`}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].numberPlant`,
                              {
                                initialValue: product?.numberPlant,
                              },
                            )(<InputNumber min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              disabled
                              value={product?.numberPlant}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={12}>
                        <Form.Item
                          className="detail"
                          label={`c) ${i18n.t(
                            'createReport.estimationForecastsStep.plantingDensity',
                          )}`}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].estimatePlantingDensity`,
                              {
                                initialValue: product?.estimatePlantingDensity,
                              },
                            )(<InputNumber min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              disabled
                              value={product?.estimatePlantingDensity}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={12}>
                        <Form.Item
                          className="detail"
                          label={`d) ${i18n.t(
                            'createReport.estimationForecastsStep.productionStatus',
                          )}`}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].estimateProductionStatus`,
                              {
                                initialValue: product?.estimateProductionStatus,
                              },
                            )(<InputNumber min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              disabled
                              value={product?.estimateProductionStatus}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={12}>
                        <Form.Item
                          className="detail"
                          label={`e) ${i18n.t(
                            'createReport.estimationForecastsStep.averageYield',
                          )}`}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].estimateAverageYield`,
                              {
                                initialValue: product?.estimateAverageYield,
                              },
                            )(<InputNumber min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              disabled
                              value={product?.estimateAverageYield}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={12}>
                        <Form.Item
                          className="detail"
                          label={`f) ${i18n.t(
                            'createReport.estimationForecastsStep.averageYieldByPlant',
                          )}`}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].estimateAverageYieldByPlant`,
                              {
                                initialValue:
                                  product?.estimateAverageYieldByPlant,
                              },
                            )(<InputNumber min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              disabled
                              value={product?.estimateAverageYieldByPlant}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={24}>
                        <div className="title">Résultats</div>
                      </Col>

                      <Col lg={24}>
                        <Form.Item
                          className="detail"
                          label={i18n.t(
                            'createReport.estimationForecastsStep.calculEstimateAverageYield',
                          )}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].calculEstimateAverageYield`,
                              {
                                initialValue:
                                  product?.calculEstimateAverageYield,
                              },
                            )(<InputNumber disabled min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              min={0}
                              disabled
                              value={product?.calculEstimateAverageYield}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={24}>
                        <Form.Item
                          className="detail"
                          label={i18n.t(
                            'createReport.estimationForecastsStep.calculEstimateAverageYieldByPlant',
                          )}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].calculEstimateAverageYieldByPlant`,
                              {
                                initialValue:
                                  product?.calculEstimateAverageYieldByPlant,
                              },
                            )(<InputNumber disabled min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              min={0}
                              disabled
                              value={product?.calculEstimateAverageYieldByPlant}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={24}>
                        <Form.Item
                          className="detail"
                          label={i18n.t(
                            'createReport.estimationForecastsStep.calculEstimateAverageYieldByDensity',
                          )}
                        >
                          {getFieldDecorator &&
                            getFieldDecorator(
                              `parcelles[${index}].products.${key}[${i}].calculEstimateAverageYieldByDensity`,
                              {
                                initialValue:
                                  product?.calculEstimateAverageYieldByDensity,
                              },
                            )(<InputNumber disabled min={0} />)}
                          {!getFieldDecorator && (
                            <InputNumber
                              min={0}
                              disabled
                              value={
                                product?.calculEstimateAverageYieldByDensity
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col lg={24}>
                        <Button
                          type="primary"
                          onClick={() =>
                            handleCalculComplexe(
                              `parcelles[${index}].products.${key}[${i}]`,
                            )
                          }
                        >
                          Calculer
                        </Button>
                      </Col>
                    </div>
                  )}
                </Row>
              </Form>
            </div>
          </div>
        )),
      )}

      <AddProductForParcellesPopup
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        currentParcelleIndex={selectedParcelleIndex}
      />
    </ParcelleDetailFormStyles>
  );
};

ParcelleDetailForm.propTypes = {
  data: PropTypes.object.isRequired,
  getFieldDecorator: PropTypes.object,
  index: PropTypes.number,
  getFieldValue: PropTypes.object,
  setFieldsValue: PropTypes.object,
  onRemove: PropTypes.func,
  isLastReport: PropTypes.bool,
  onShowLastReport: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default ParcelleDetailForm;
