import { takeEvery, put, select, call } from 'redux-saga/effects';
import { showErrorNoti } from 'utils/notifications';
import { getAllReport, postNewReport } from 'api/reports';
import { uploadPhoto } from 'api/media';
import { omit } from 'lodash';
import { apiWrapper } from '../utils';
import { actions } from './index';
import { settingActions } from '../setting/index';

function* addNewReportSaga({ payload, deferred: { resolve, reject } }) {
  try {
    yield put(actions.addNewReportSuccess(payload));
    resolve(payload);
  } catch (ex) {
    showErrorNoti(ex);
    reject(ex);
  }
}

function* getAllReportSaga({ payload }) {
  try {
    const { data } = yield call(
      apiWrapper,
      { isShowError: false },
      getAllReport,
      payload,
    );
    yield put(actions.getAllReportSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* addNewReportToSyncSaga({ deferred }) {
  try {
    const currentNewReport = yield select(
      state => state.reports.currentNewReport,
    );
    const { isOffline } = yield select(state => state.setting);

    if (isOffline) {
      yield put(
        actions.addNewReportToSyncSuccess({
          ...currentNewReport,
          id: `offlineReport-${Date.now()}`,
        }),
      );
    } else {
      const postParam = {
        payload: [
          {
            ...currentNewReport,
            farmerId:
              typeof currentNewReport.farmerId === 'string' &&
              currentNewReport.farmerId.indexOf('offlineFarmer') > -1
                ? null
                : currentNewReport.farmerId,
          },
        ],
      };

      yield call(apiWrapper, { isShowError: true }, postNewReport, postParam);
      yield put(settingActions.getListFarmers());
    }

    yield put(actions.clearNewReportSuccess());
    deferred.resolve();
  } catch (ex) {
    deferred.reject();
    console.error(ex);
  }
}

function* clearReportSaga({ payload, deferred }) {
  try {
    yield put(actions.clearNewReportSuccess());
    deferred.resolve();
  } catch (ex) {
    console.error(ex);
  }
}

function* uploadPhotoSaga({ payload: { localStoreKey } }) {
  try {
    const { isOffline } = yield select(state => state.setting);

    if (isOffline) return;
    const data = yield call(
      uploadPhoto,
      localStorage.getItem(localStoreKey),
      localStoreKey,
    );
    yield put(
      actions.uploadPhotoSuccess({
        localStoreKey,
        photoUrl: `${data.public_id}`,
      }),
    );
    localStorage.removeItem(localStoreKey);
  } catch (ex) {
    console.error(ex);
  }
}

function* sendListNewReportsToSyncSaga({ deferred }) {
  try {
    const { isOffline } = yield select(state => state.setting);
    if (isOffline) {
      deferred.reject();
      return;
    }

    const listNewReportsToSync = yield select(
      state => state.reports.listNewReportsToSync,
    );

    const postParam = {
      payload: listNewReportsToSync.map(e => omit(e, 'id')),
    };

    const { data } = yield call(
      apiWrapper,
      { isShowError: true },
      postNewReport,
      postParam,
    );

    yield put(actions.clearListNewReportsToSync());
    yield put(settingActions.getListFarmers());
    yield put(actions.getAllReport());
    deferred.resolve();
  } catch (ex) {
    console.error(ex);
  }
}

export default [
  takeEvery(actions.addNewReport, addNewReportSaga),
  takeEvery(actions.clearNewReport, clearReportSaga),
  takeEvery(actions.getAllReport, getAllReportSaga),
  takeEvery(actions.addNewReportToSync, addNewReportToSyncSaga),
  takeEvery(actions.sendListNewReportsToSync, sendListNewReportsToSyncSaga),
  takeEvery(actions.uploadPhoto, uploadPhotoSaga),
];
