import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { isNaN } from 'lodash';
import CounterStyles from './styles';

const Counter = ({ onUpdateNumber, productId, defaultValue = 1, ...props }) => {
  const [value, setValue] = useState(defaultValue);

  const onMinus = () => {
    if (onUpdateNumber) onUpdateNumber(productId, value - 1);

    setValue(value - 1);
  };

  const onPlus = () => {
    if (onUpdateNumber) onUpdateNumber(productId, value + 1);

    setValue(value + 1);
  };

  const onChange = e => {
    const { value: val } = e.target;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if (!isNaN(val) && reg.test(val)) {
      if (onUpdateNumber) onUpdateNumber(productId, parseInt(val, 10));

      setValue(parseInt(val, 10));
    }
  };

  return (
    <CounterStyles>
      <Button type="primary" icon="minus" size="small" onClick={onMinus} />
      <Input
        min={1}
        size="small"
        value={value}
        onChange={onChange}
        {...props}
      />
      <Button type="primary" icon="plus" size="small" onClick={onPlus} />
    </CounterStyles>
  );
};

Counter.propTypes = {
  onUpdateNumber: PropTypes.func,
  productId: PropTypes.any,
  defaultValue: PropTypes.number,
};

export default Counter;
