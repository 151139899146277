import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import ProductCardStyles from './styles';

const ProductCard = ({ product, quantity }) => (
  <ProductCardStyles>
    <div className="product-section">
      {product?.photo && (
        <img
          src={`${process.env.REACT_APP_CLOUDINARY_URL}/w_400,h_400,c_crop,g_face,r_max/w_100/${product?.photo}`}
          alt={product?.nom}
          title={product?.nom}
        />
      )}
      {product?.nom && <div className="name">{product?.nom}</div>}
      <div className="quantity-title">
        {i18n.t('createReport.summaryStep.quantity')}
      </div>
      {product?.estimateAverageYield && (
        <div className="quantity-info">{product?.estimateAverageYield}</div>
      )}
      {quantity && <div className="quantity-info">{quantity}</div>}
    </div>
  </ProductCardStyles>
);

ProductCard.propTypes = {
  product: PropTypes.object,
  quantity: PropTypes.any,
};

export default ProductCard;
