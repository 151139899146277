import styled from 'styled-components';
import theme from 'configs/theme';

export const ParcelleDetailSummaryStyles = styled.div``;

export default styled.div`
  .title-summary {
    text-align: center;
    font-size: ${theme.fontSize.normal};
    font-weight: bold;
    text-transform: uppercase;
    margin: 15px 0 25px;
  }

  .list-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-section {
    margin: 0 25px;

    .title-div {
      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
      }

      .subTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info-section {
          .info {
            font-size: 16px;
            font-weight: bold;
          }
        }

        .action-section {
          flex-wrap: wrap;
          button {
            margin-top: 0;
            margin-bottom: 0;
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .product-form {
      display: flex;
      flex: 1;
      margin: 20px 0;
      padding: 10px 30px 0;
      background-color: rgba(255, 255, 255, 0.65);
      border-radius: 10px;

      .img-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px;

        img {
          border-radius: 50%;
          width: 80px;
        }

        .name {
          font-size: 18px;
          font-weight: bold;
          margin: 20px 0;
        }
      }

      .ant-form {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .quantity-info,
  .ant-descriptions-item-content {
    font-size: 16px;
    font-weight: bold;
  }

  .form-section {
    display: flex;

    .ant-descriptions {
      display: flex;
      align-items: center;
    }
  }
`;
