import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Upload, Icon } from 'antd';
import { actions } from 'redux/reports';
// import components
import AvatarCropperModal from 'components/UploadImage';

const UploadImagePWA = ({ dataKey }) => {
  const dispatch = useDispatch();
  const [fileSelected, setFileSelected] = useState(null);
  const [isShowCropperModal, setIsShowCropperModal] = useState(false);
  const props = {
    showUploadList: false,
    beforeUpload: file => {
      setFileSelected(file);
      setIsShowCropperModal(true);
      return false;
    },
  };

  const onHideCropperModal = () => {
    setIsShowCropperModal(false);
  };

  const handleChange = async ({ croppedFile }) => {
    const prefix = 'cloudy_pwa_';
    // create random string
    const rs = Math.random()
      .toString(36)
      .substr(2, 10);
    localStorage.setItem(`${prefix}${rs}`, croppedFile);
    // alert(
    //   'Image saved locally, it will be uploaded to your Cloudinary media library once internet connection is detected',
    // );

    dispatch(
      actions.uploadPhoto({
        dataKey,
        localStoreKey: `${prefix}${rs}`,
      }),
    );
  };

  return (
    <div className="clearfix">
      <Upload {...props}>
        {/* {uploadButton} */}
        <Icon className="ic-camera" type="camera" />
      </Upload>
      <AvatarCropperModal
        isShowModal={isShowCropperModal}
        onHideModal={onHideCropperModal}
        onChangePreview={handleChange}
        file={fileSelected}
      />
    </div>
  );
};

UploadImagePWA.propTypes = {
  dataKey: PropTypes.string,
};

export default UploadImagePWA;
