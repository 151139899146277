import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Select, InputNumber, Input, Button } from 'antd';
import i18n from 'i18next';
import { ParcelleFormStyles } from './styles';

const { TextArea } = Input;
const { Option } = Select;

const ParcelleForm = ({ getFieldDecorator, data, index, onRemove }) => {
  const listMunicipalities = useSelector(
    state => state.setting.listMunicipalities,
  );

  const listMethodsOfCultures = useSelector(
    state => state.setting.listMethodsOfCultures,
  );

  const listActivitiesTypes = useSelector(
    state => state.setting.listActivitiesTypes,
  );

  const listCulturesTypes = useSelector(
    state => state.setting.listCulturesTypes,
  );

  const listIslands = useSelector(state => state.setting.listIslands);

  const onClick = () => {
    onRemove(index);
  };

  if (!data) return null;

  return (
    <ParcelleFormStyles className="parcelle-form">
      <div className="form-div">
        <Form.Item
          label={i18n.t('createReport.theFarmerStep.id')}
          style={{ display: 'none' }}
        >
          {getFieldDecorator(`parcelles[${index}].plotId`, {
            initialValue: data?.plotId || null,
          })(<Input type="text" />)}
        </Form.Item>

        <Form.Item label={i18n.t('createReport.theFarmerStep.island')}>
          {getFieldDecorator(`parcelles[${index}].farmerIsland`, {
            rules: [{ required: true }],
            initialValue: data?.farmerIsland || '',
          })(
            <Select placeholder="Please select the option">
              {listIslands?.map(obj => (
                <Option key={obj.id} value={obj.id}>
                  {obj.nom}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label={i18n.t('createReport.theFarmerStep.municipality')}>
          {getFieldDecorator(`parcelles[${index}].farmerMunicipality`, {
            rules: [{ required: true }],
            initialValue: data?.farmerMunicipality || '',
          })(
            <Select placeholder="Please select the option">
              {listMunicipalities?.map(obj => (
                <Option key={obj.id} value={obj.id}>
                  {obj.nom}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label={i18n.t('createReport.theFarmerStep.cultureMethod')}>
          {getFieldDecorator(`parcelles[${index}].farmerCultureMethod`, {
            rules: [{ required: true }],
            initialValue: data?.farmerCultureMethod || '',
          })(
            <Select placeholder="Please select the option">
              {listMethodsOfCultures?.map(obj => (
                <Option key={obj.id} value={obj.id}>
                  {obj.nom}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label={i18n.t('createReport.theFarmerStep.typeActivity')}>
          {getFieldDecorator(`parcelles[${index}].farmerTypeActivity`, {
            rules: [{ required: true }],
            initialValue: data?.farmerTypeActivity || [],
          })(
            <Select mode="multiple" placeholder="Please select the option">
              {listActivitiesTypes?.map(obj => (
                <Option key={obj.id} value={obj.id}>
                  {obj.nom}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label={i18n.t('createReport.theFarmerStep.typeCulture')}>
          {getFieldDecorator(`parcelles[${index}].farmerTypeCulture`, {
            rules: [{ required: true }],
            initialValue: data?.farmerTypeCulture || '',
          })(
            <Select placeholder="Please select the option">
              {listCulturesTypes?.map(obj => (
                <Option key={obj.id} value={obj.id}>
                  {obj.nom}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label={i18n.t('createReport.theFarmerStep.plotArea')}>
          {getFieldDecorator(`parcelles[${index}].farmerPlotArea`, {
            rules: [{ required: true }],
            initialValue: data?.farmerPlotArea || 0,
          })(<InputNumber min={0} />)}
        </Form.Item>

        <Form.Item
          className="futherInfo"
          label={i18n.t('createReport.theFarmerStep.futherInfo')}
        >
          {getFieldDecorator(`parcelles[${index}].farmerFutherInfo`, {
            initialValue: data?.farmerFutherInfo || '',
          })(<TextArea rows={6} />)}
        </Form.Item>
      </div>
      <div className="action-div">
        <Button type="primary" className="cancel-btn red-btn" onClick={onClick}>
          {i18n.t('createReport.theFarmerStep.deleteNewParcelleBtn')}
        </Button>
      </div>
    </ParcelleFormStyles>
  );
};

ParcelleForm.propTypes = {
  getFieldDecorator: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  onRemove: PropTypes.func,
};

export default ParcelleForm;
