import { takeEvery, all, put, call, select } from 'redux-saga/effects';
// import api
import {
  getListActivitiesTypesApi,
  getListCulturesTypesApi,
  getListMunicipalitiesApi,
  getListIslandsApi,
  getListMethodsOfCulturesApi,
  getListPlotsApi,
  getListFarmersApi,
  getListProductsApi,
  getListSocialSecurityApi,
} from 'api/setting';
// import actions
import { prefetchCloudinaryImage } from 'utils';
import { apiWrapper } from '../utils';
import { settingActions } from './index';
import { actions as reportActions } from '../reports/index';

const findLocalItems = query => {
  let i;
  const results = [];
  // eslint-disable-next-line
  for (i in localStorage) {
    // eslint-disable-next-line
    if (localStorage.hasOwnProperty(i)) {
      if (i.match(query) || (!query && typeof i === 'string')) {
        const value = localStorage.getItem(i);
        results.push({ key: i, val: value });
      }
    }
  }
  return results;
};

// eslint-disable-next-line
function* setOnlineSaga() {
  try {
    const images = findLocalItems(/^cloudy_pwa_/);
    if (images.length > 0) {
      yield all(
        images.map(e =>
          put(reportActions.uploadPhoto({ localStoreKey: e.key })),
        ),
      );
    }
  } catch (ex) {
    console.error(ex);
    console.log('images', ex);
  }
}

function* fetchAllSettingsSaga() {
  try {
    yield put(settingActions.getListActivitiesTypes());
    yield put(settingActions.getListCulturesTypes());
    yield put(settingActions.getListCategoriesOfProduct());
    yield put(settingActions.getListMunicipalities());
    yield put(settingActions.getListIslands());
    yield put(settingActions.getListMethodsOfCultures());
    yield put(settingActions.getListPlots());
    yield put(settingActions.getListFarmers());
    yield put(settingActions.getListProducts());
    yield put(settingActions.getListSocialSecurity());
  } catch (ex) {
    console.error(ex);
  }
}

function* getListActivitiesTypesSaga({ payload }) {
  try {
    const { data } = yield call(
      apiWrapper,
      null,
      getListActivitiesTypesApi,
      payload,
    );

    yield put(settingActions.getListActivitiesTypesSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* getListCulturesTypesSaga({ payload }) {
  try {
    const { data } = yield call(
      apiWrapper,
      null,
      getListCulturesTypesApi,
      payload,
    );
    yield put(settingActions.getListCulturesTypesSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* getListMunicipalitiesSaga({ payload }) {
  try {
    const { data } = yield call(
      apiWrapper,
      null,
      getListMunicipalitiesApi,
      payload,
    );
    yield put(settingActions.getListMunicipalitiesSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* getListIslandsSaga({ payload }) {
  try {
    const { data } = yield call(apiWrapper, null, getListIslandsApi, payload);
    yield put(settingActions.getListIslandsSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* getListMethodsOfCulturesSaga({ payload }) {
  try {
    const { data } = yield call(
      apiWrapper,
      null,
      getListMethodsOfCulturesApi,
      payload,
    );
    yield put(settingActions.getListMethodsOfCulturesSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* getListPlotsSaga({ payload }) {
  try {
    const { data } = yield call(apiWrapper, null, getListPlotsApi, payload);
    yield put(settingActions.getListPlotsSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* getListFarmersSaga({ payload }) {
  try {
    const { data } = yield call(apiWrapper, null, getListFarmersApi, payload);
    yield put(settingActions.getListFarmersSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* getListProductsSaga({ payload }) {
  try {
    const { data } = yield call(apiWrapper, null, getListProductsApi, payload);
    // Prefetch product images
    if (data?.data) {
      data.data.forEach(product => {
        prefetchCloudinaryImage(product.photo);
      });
    }
    yield put(settingActions.getListProductsSuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

function* getListSocialSecuritySaga({ payload }) {
  try {
    const { data } = yield call(
      apiWrapper,
      null,
      getListSocialSecurityApi,
      payload,
    );
    yield put(settingActions.getListSocialSecuritySuccess(data.data));
  } catch (ex) {
    console.error(ex);
  }
}

export default [
  takeEvery(settingActions.fetchAllSettings, fetchAllSettingsSaga),
  takeEvery(settingActions.setOnline, setOnlineSaga),
  takeEvery(settingActions.getListActivitiesTypes, getListActivitiesTypesSaga),
  takeEvery(settingActions.getListCulturesTypes, getListCulturesTypesSaga),
  takeEvery(settingActions.getListMunicipalities, getListMunicipalitiesSaga),
  takeEvery(settingActions.getListIslands, getListIslandsSaga),
  takeEvery(
    settingActions.getListMethodsOfCultures,
    getListMethodsOfCulturesSaga,
  ),
  takeEvery(settingActions.getListPlots, getListPlotsSaga),
  takeEvery(settingActions.getListFarmers, getListFarmersSaga),
  takeEvery(settingActions.getListProducts, getListProductsSaga),
  takeEvery(settingActions.getListSocialSecurity, getListSocialSecuritySaga),
];
