import axios from '../index';

export function getAllReport() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_SERVER_URL}/rapports`,
  });
}

export function postNewReport({ payload }) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SERVER_URL}/rapports`,
    data: {
      ...payload,
    },
  });
}
