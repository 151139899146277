import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { groupBy } from 'lodash';
import { Drawer, Button } from 'antd';
// import components
import ProductCard from 'components/ProductCard';
import ParcelleDetailSummary from 'containers/ReportDetail/ParcelleDetailSummary';
import { SummaryStyles } from './styles';

const LastReportDrawer = ({ onClose, visible, currentReport }) => {
  const estimationsAgents = groupBy(
    currentReport?.estimations_agents,
    'parcelle.id',
  );
  const rebuildEstimationsAgents = Object.keys(estimationsAgents).map(data => {
    return {
      ...estimationsAgents[data][0].parcelle,
      estimations_agents: estimationsAgents[data],
    };
  });
  return (
    <Drawer
      placement="bottom"
      closable={false}
      onClose={onClose}
      visible={visible}
      height="100%"
      className="full-screen-drawer"
    >
      <SummaryStyles>
        <div className="content-section">
          <div className="title-summary">
            {i18n.t('createReport.summaryStep.estimatedMarketingTitle')}
          </div>
          <div className="list-product">
            {currentReport?.estimations_agriculteurs?.map(data => (
              <ProductCard
                product={data?.produit}
                quantity={data?.quantite_kg}
              />
            ))}
          </div>
        </div>

        <div className="content-section">
          <div className="title-summary">
            {i18n.t('createReport.summaryStep.agentEstimateTitle')}
          </div>
          {rebuildEstimationsAgents?.map((data, index) => (
            <ParcelleDetailSummary
              data={data}
              index={index}
              estimations_agents={estimationsAgents}
            />
          ))}
        </div>

        <Button className="backBtn" type="primary" onClick={onClose}>
          {i18n.t('createReport.cancelBtn')}
        </Button>
      </SummaryStyles>
    </Drawer>
  );
};

LastReportDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  currentReport: PropTypes.object,
};

export default LastReportDrawer;
