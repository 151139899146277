import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { compact } from 'lodash';
import { Form, Input, InputNumber, Button, Select } from 'antd';
// import actions
import { actions } from 'redux/reports';
// import components
import Title from 'components/Title';
// import containers
import { settingActions } from 'redux/setting';
import ParcelleForm from './ParcelleForm';
import { TheNewFarmerStyles } from './styles';

const { TextArea } = Input;
const { Option } = Select;

const TheNewFarmer = ({ newReportsToSync, form, onBack, onNext }) => {
  const listMunicipalities = useSelector(
    state => state.setting.listMunicipalities,
  );
  const listMethodsOfCultures = useSelector(
    state => state.setting.listMethodsOfCultures,
  );
  const listActivitiesTypes = useSelector(
    state => state.setting.listActivitiesTypes,
  );
  const listCulturesTypes = useSelector(
    state => state.setting.listCulturesTypes,
  );
  const listIslands = useSelector(state => state.setting.listIslands);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const report = useSelector(state => state.reports.currentNewReport);
  const currentNewReport = newReportsToSync || report;
  const [listParcellesTemp, setListParcellesTemp] = useState(
    currentNewReport?.parcelles || [{}],
  );
  const dispatch = useDispatch();
  const listSocialSecurity = useSelector(
    state => state.setting.listSocialSecurity,
  );

  const { getFieldDecorator } = form;
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const offlineFarmerId = `offlineFarmer-${Date.now()}`;
        // eslint-disable-next-line
        if (!values.farmerId) {
          const formatPlots = values.parcelles.map((parcelle, index) => {
            return {
              id: null,
              ...parcelle,
              superficie: parcelle.farmerPlotArea,
              informations_complementaires: parcelle.farmerFutherInfo,
              commune: listMunicipalities.find(
                e => e.id === parcelle.farmerMunicipality,
              ),
              type_culture: listCulturesTypes.find(
                e => e.id === parcelle.farmerTypeCulture,
              ),
              type_activite: listActivitiesTypes.find(
                e => e.id === parcelle.farmerTypeActivity,
              ),
              methode_culture: listMethodsOfCultures.find(
                e => e.id === parcelle.farmerCultureMethod,
              ),
            };
          });

          dispatch(
            settingActions.setListNewFarmers({
              id: null,
              syncOfflineFarmerId: offlineFarmerId,
              nom: values.farmerName,
              prenom: values.farmerFirstName,
              portable: values.farmerPhoneNumber,
              fixe: values.farmerEmail,
              email_secondaire: values.farmerSecondaryEmail,
              employes: values.farmerNumberEmployees,
              informations_complementaires: values.farmerFutherInfo,
              type_regime_social: listSocialSecurity.find(
                e => e.id === values.farmerSocialSecurity,
              ),
              parcelles: formatPlots,
            }),
          );
        }

        if (values?.parcelles) values.parcelles = compact(values.parcelles);

        if (newReportsToSync) {
          dispatch(
            actions.updateNewReportToSync({
              ...newReportsToSync,
              ...values,
              parcelles: values?.parcelles?.map((par, index) => {
                if (newReportsToSync?.parcelles[index]) {
                  return {
                    ...newReportsToSync?.parcelles[index],
                    ...par,
                  };
                }
                return par;
              }),
            }),
          );
          onNext();
          return;
        }
        setIsSubmiting(true);
        dispatch(
          actions.addNewReport({
            ...values,
          }),
        )
          .then(() => {
            setIsSubmiting(false);
            onNext();
            setListParcellesTemp(currentNewReport?.parcelles);
          })
          .catch(() => {
            setIsSubmiting(false);
          });
      }
    });
  };

  const onCreateParcelle = () => {
    setListParcellesTemp([...listParcellesTemp, {}]);
  };

  const onRemoveParcelle = index => {
    setListParcellesTemp(
      listParcellesTemp.map((par, i) => {
        if (index === i) return null;
        return par;
      }),
    );
  };

  return (
    <TheNewFarmerStyles>
      <Title title={i18n.t('createReport.theFarmerStep.title', { step: 2 })} />
      <div className="form-content">
        <Form layout="vertical">
          <div className="info-section">
            <div className="title-sub-section">
              {i18n.t('createReport.theFarmerStep.infoSectionTitle')}
            </div>
            <br />
            <div className="content-sub-section">
              <Form.Item
                label={i18n.t('createReport.theFarmerStep.id')}
                style={{ display: 'none' }}
              >
                {getFieldDecorator('farmerId', {
                  initialValue: currentNewReport?.farmerId || null,
                })(<Input />)}
              </Form.Item>

              <Form.Item label={i18n.t('createReport.theFarmerStep.name')}>
                {getFieldDecorator('farmerName', {
                  rules: [{ required: true }],
                  initialValue: currentNewReport?.farmerName || '',
                })(<Input />)}
              </Form.Item>

              <Form.Item label={i18n.t('createReport.theFarmerStep.firstName')}>
                {getFieldDecorator('farmerFirstName', {
                  rules: [{ required: true }],
                  initialValue: currentNewReport?.farmerFirstName || '',
                })(<Input />)}
              </Form.Item>

              <Form.Item
                label={i18n.t('createReport.theFarmerStep.phoneNumber')}
              >
                {getFieldDecorator('farmerPhoneNumber', {
                  rules: [{ required: true }],
                  initialValue: currentNewReport?.farmerPhoneNumber || '',
                })(<Input />)}
              </Form.Item>
              <Form.Item label={i18n.t('createReport.theFarmerStep.homePhone')}>
                {getFieldDecorator('farmerHomePhone', {
                  initialValue: currentNewReport?.farmerHomePhone || '',
                })(<Input />)}
              </Form.Item>
              <Form.Item label={i18n.t('createReport.theFarmerStep.email')}>
                {getFieldDecorator('farmerEmail', {
                  initialValue: currentNewReport?.farmerEmail || '',
                })(<Input />)}
              </Form.Item>
              <Form.Item
                label={i18n.t('createReport.theFarmerStep.secondaryEmail')}
              >
                {getFieldDecorator('farmerSecondaryEmail', {
                  initialValue: currentNewReport?.farmerSecondaryEmail || '',
                })(<Input />)}
              </Form.Item>
              <Form.Item
                label={i18n.t('createReport.theFarmerStep.socialSecurity')}
              >
                {getFieldDecorator('farmerSocialSecurity', {
                  initialValue: currentNewReport?.farmerSocialSecurity || '',
                })(
                  <Select placeholder="Please select the option">
                    {listSocialSecurity?.map(obj => (
                      <Option key={obj.id} value={obj.id}>
                        {obj.nom}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item
                label={i18n.t('createReport.theFarmerStep.numberEmployees')}
              >
                {getFieldDecorator('farmerNumberEmployees', {
                  initialValue: currentNewReport?.farmerNumberEmployees || '',
                })(<InputNumber />)}
              </Form.Item>
              <Form.Item
                className="futherInfo"
                label={i18n.t('createReport.theFarmerStep.futherInfo')}
              >
                {getFieldDecorator('farmerFutherInfo', {
                  initialValue: currentNewReport?.farmerFutherInfo || '',
                })(<TextArea rows={6} />)}
              </Form.Item>
            </div>
          </div>

          <div className="info-section">
            <div className="title-sub-section">
              {i18n.t('createReport.theFarmerStep.plotsInfoSectionTitle')}
            </div>
            <br />
            <div className="content-sub-section">
              {listParcellesTemp?.map((parcelle, index) => (
                <ParcelleForm
                  key={index}
                  getFieldDecorator={getFieldDecorator}
                  index={index}
                  data={parcelle}
                  onRemove={onRemoveParcelle}
                />
              ))}
            </div>
          </div>
        </Form>
        <div className="list-actions-section">
          <div className="action-section">
            <Button
              type="primary"
              className="right-btn"
              onClick={onCreateParcelle}
            >
              {i18n.t('createReport.theFarmerStep.createNewParcelleBtn')}
            </Button>
          </div>
          <div className="action-section">
            <Button type="primary" className="cancel-btn" onClick={onBack}>
              {i18n.t('createReport.cancelBtn')}
            </Button>
            <Button type="primary" onClick={handleSubmit} loading={isSubmiting}>
              {i18n.t('createReport.nextBtn')}
            </Button>
          </div>
        </div>
      </div>
    </TheNewFarmerStyles>
  );
};

TheNewFarmer.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  form: PropTypes.object,
  newReportsToSync: PropTypes.object,
};

export default Form.create({ name: 'the_new_farmer_step' })(TheNewFarmer);
