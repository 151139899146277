import React from 'react';
import { useHistory } from 'react-router';
import {Button} from "antd";
import StyledGoBackButton from './styles';

const GoBackButton = () => {
  const history = useHistory();

  return (
    <StyledGoBackButton>
      <Button type="primary" onClick={history.goBack}>Retour</Button>
    </StyledGoBackButton>
  );
};

export default GoBackButton;
