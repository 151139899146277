import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import {Layout, Icon, Drawer, Menu, Alert, notification, Button} from 'antd';
import i18n from 'i18next';
// import actions
import { settingActions } from 'redux/setting';
import { actions as reportActions } from 'redux/reports';
import { authActions } from 'redux/auth';
import PrivateLayoutWrapper from './styles';

const { Header, Content, Footer } = Layout;
const sidebarMenu = [
  {
    key: 'homepage',
    text: 'homepage.titleBar',
    icon: 'home',
    url: '/',
  },
  {
    key: 'createReport',
    text: 'createReport.titleBar',
    icon: 'form',
    url: '/create-new-report',
  },
  {
    key: 'listAllReport',
    text: 'listAllReport.titleBar',
    icon: 'eye',
    url: '/#',
  },
];

const PrivateLayout = ({ children, history }) => {
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const currentUser = useSelector(state => state.auth.currentUser);
  const isOffline = useSelector(state => state.setting.isOffline);
  const listReportsToSync = useSelector(
    state => state.reports.listNewReportsToSync,
  );

  useEffect(() => {
    // fetch all setting config
    if (isAuthenticated) {
      dispatch(settingActions.fetchAllSettings());
      dispatch(reportActions.getAllReport());
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (!Cookies.get('authToken')) {
      history.push('/login');
    }

    window.addEventListener('online', () => {
      dispatch(settingActions.setOnline());
      dispatch(settingActions.fetchAllSettings());
      if (listReportsToSync.length > 0) {
        dispatch(reportActions.sendListNewReportsToSync())
          .then(() => {
            notification.info({
              message: 'Synchronisation éffectué !',
            });
            dispatch(reportActions.getAllReport());
          })
          .catch(() => {
            notification.info({
              message:
                'Vous devez être connecté à Internet pour synchroniser vos rapports !',
            });
            dispatch(reportActions.getAllReport());
          });
      } else {
        dispatch(reportActions.getAllReport());
      }
    });

    window.addEventListener('offline', () => {
      dispatch(settingActions.setOffline());
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isAuthenticated && Cookies.get('authToken')) {
      dispatch(authActions.getCurrentUser());
    }

    // eslint-disable-next-line
  }, []);

  if (!isAuthenticated && !Cookies.get('authToken')) {
    return <Redirect to="/login" />;
  }

  const showDrawer = () => {
    setIsShowDrawer(true);
  };

  const onClose = () => {
    setIsShowDrawer(false);
  };

  const onLogout = () => {
    if (isOffline) {
      notification.info({
        message: 'Veuillez vous connecter à Internet pour vous déconnecter',
      });
      return;
    }

    if (isLogoutLoading) return;
    setIsLogoutLoading(true);

    dispatch(authActions.logout()).then(() => {
      setIsLogoutLoading(false);
    });
  };

  return (
    <PrivateLayoutWrapper>
      <Layout className="main-layout">
        <Header className="header">
          <div>
            <Icon className="trigger" type="menu" onClick={showDrawer} />
          </div>
          <div>
            <Link to="/">
              <div className="title">{i18n.t('homepage.titleBar')}</div>
            </Link>
          </div>
          <div />
        </Header>
        {isOffline && (
          <Alert
            message="Mode Non Connecté"
            description="Vous n'êtes pas connecté à Internet, mais vous pouvez continuer à travailler."
            type="error"
            banner
            showIcon
          />
        )}

        <Content>{children}</Content>
        <Footer className="footer">CAPL © 2020</Footer>
      </Layout>

      <Drawer
        width="33%"
        height="100%"
        className="menu-drawer"
        title={
          <div className="title-section">
            <div className="title">{i18n.t('login.title')}</div>
            <div className="name">{currentUser?.name}</div>
          </div>
        }
        placement="left"
        closable={false}
        onClose={onClose}
        visible={isShowDrawer}
      >
        <div className="logout-section" onClick={onLogout} role="presentation">
          <span>{i18n.t('login.logout')}</span>
          <Icon type={isLogoutLoading ? 'loading' : 'export'} />
        </div>

        <div className="menu-section">
          <Menu mode="vertical">
            {sidebarMenu.map(menu => (
              <Menu.Item key={menu.key}>
                <Link to={menu.url} onClick={onClose}>
                  <Icon type={menu.icon} />
                  {i18n.t(menu.text)}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </Drawer>
    </PrivateLayoutWrapper>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(PrivateLayout);
