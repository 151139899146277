import styled from "styled-components";
import theme from 'configs/theme';

export default styled.div`
	text-align: center;
	padding: 20px 0;
    color: #003750;
    
	.title {
	  font-size: ${theme.fontSize.title};
      font-weight: 700;
	}
	
	.subtitle {
	  font-size:${theme.fontSize.subtitle};
	}
`;
