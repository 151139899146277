import { takeEvery, put, call } from 'redux-saga/effects';
import { setAuthToken } from 'api';
// import api
import {
  loginApi,
  getCurrentUserApi,
  logoutApi,
  signupApi,
  requestResetPasswordApi,
} from 'api/auth';
import { apiWrapper } from '../utils';
import { authActions } from './index';

function* loginSaga({ payload, deferred: { resolve, reject } }) {
  try {
    const { data } = yield call(apiWrapper, null, loginApi, payload);
    if (data && data.access_token) {
      setAuthToken(data.access_token);
    }
    yield put(authActions.getCurrentUser());
    resolve();
  } catch (ex) {
    reject(ex);
  }
}

function* getCurrentUserSaga() {
  try {
    const { data } = yield call(apiWrapper, null, getCurrentUserApi);
    yield put(authActions.getCurrentUserSuccess(data));
  } catch (ex) {
    console.log(ex);
  }
}

function* logoutSaga({ deferred: { resolve } }) {
  try {
    const { data } = yield call(apiWrapper, null, logoutApi);
    yield put(authActions.logoutSuccess());
    setAuthToken();
    resolve(data);
  } catch (ex) {
    resolve(ex);
    console.log(ex);
  }
}

function* signupSaga({ payload, deferred: { resolve } }) {
  try {
    const { data } = yield call(apiWrapper, null, signupApi, payload);
    resolve(data);
  } catch (ex) {
    console.log(ex);
  }
}

function* requestResetPasswordSaga({ payload, deferred: { resolve } }) {
  try {
    const { data } = yield call(
      apiWrapper,
      null,
      requestResetPasswordApi,
      payload,
    );
    resolve(data);
  } catch (ex) {
    console.log(ex);
  }
}

export default [
  takeEvery(authActions.login, loginSaga),
  takeEvery(authActions.getCurrentUser, getCurrentUserSaga),
  takeEvery(authActions.logout, logoutSaga),
  takeEvery(authActions.signup, signupSaga),
  takeEvery(authActions.requestResetPassword, requestResetPasswordSaga),
];
