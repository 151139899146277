import { set } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const flattenObj = (value, currentKey) => {
  let result = {};

  Object.keys(value).forEach(key => {
    const tempKey = currentKey ? `${currentKey}.${key}` : key;

    if (typeof value[key] !== 'object') {
      result[tempKey] = value[key];
    } else {
      result = { ...result, ...flattenObj(value[key], tempKey) };
    }
  });

  return result;
};

export const INITIAL_STATE = {
  currentNewReport: null,
  listNewReportsToSync: [],
  listReports: [],
};

const uploadPhoto = (state, { payload: { dataKey, localStoreKey } }) => {
  dataKey && set(state.currentNewReport, dataKey, localStoreKey);
  return state;
};

const uploadPhotoSuccess = (
  state,
  { payload: { localStoreKey, photoUrl } },
) => {
  const currentNewReport = flattenObj(state.currentNewReport);
  Object.keys(currentNewReport).forEach(key => {
    if (currentNewReport[key] === localStoreKey) {
      set(state.currentNewReport, key, photoUrl);
    }
  });
  return state;
};

const addNewReportSuccess = (state, { payload }) => {
  state.currentNewReport = { ...state.currentNewReport, ...payload };
};

const updateNewReportToSync = (state, { payload }) => {
  state.listNewReportsToSync = state.listNewReportsToSync.map(e =>
    e.id === payload.id ? { ...e, ...payload } : e,
  );
};

const addNewReportToSyncSuccess = (state, { payload }) => {
  state.listNewReportsToSync = [...state.listNewReportsToSync, payload];
};

const clearNewReportSuccess = state => {
  state.currentNewReport = null;
};

const getAllReportSuccess = (state, { payload }) => {
  state.listReports = payload;
};

const clearListNewReportsToSync = state => {
  state.listNewReportsToSync = [];
};

const authSlice = createSlice({
  name: 'reports',
  initialState: INITIAL_STATE,
  reducers: {
    addNewReport: {
      reducer: () => {},
      prepare: payload => ({
        payload,
        meta: { async: true },
      }),
    },
    addNewReportSuccess,
    updateNewReportToSync,
    addNewReportToSync: {
      reducer: () => {},
      prepare: payload => ({
        payload,
        meta: { async: true },
      }),
    },
    addNewReportToSyncSuccess,
    clearNewReport: {
      reducer: () => {},
      prepare: payload => ({
        payload,
        meta: { async: true },
      }),
    },
    clearNewReportSuccess,
    sendListNewReportsToSync: {
      reducer: () => {},
      prepare: payload => ({
        payload,
        meta: { async: true },
      }),
    },
    clearListNewReportsToSync,
    getAllReport: {
      reducer: () => {},
      prepare: payload => ({
        payload,
        meta: { isOfflineMode: true },
      }),
    },
    getAllReportSuccess,
    uploadPhoto,
    uploadPhotoSuccess,
  },
});

export const { actions, reducer } = authSlice;

export default reducer;
