import styled from 'styled-components';
import theme from 'configs/theme';

export default styled.div`
  .title {
    text-align: center;
    text-transform: uppercase;
    margin: 15px 0;
  }

  .content-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .action-section {
    text-align: center;
    margin: 25px 0;

    button {
      margin: 0 30px;
    }

    .list-report-btn {
      background-color: ${theme.palette.blue};
      border-color: ${theme.palette.blue};
    }

    .sync-report-btn {
      background-color: ${theme.palette.greenBlue};
      border-color: ${theme.palette.greenBlue};
    }
  }
`;
