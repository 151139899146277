import { takeEvery, put, select, all } from 'redux-saga/effects';
import { actions } from './index';
import { settingActions } from '../setting';

function* reloadOfflineSaga() {
  try {
    const { offlineActions } = yield select(state => state.offlineStack);
    if (offlineActions.length > 0) {
      yield all(offlineActions.map(e => put(e)));
    }
  } catch (ex) {
    console.error(ex);
  }
}

function* setOnlineSaga() {
  try {
    yield put(actions.reloadOffline());
  } catch (ex) {
    console.error(ex);
  }
}
export default [
  takeEvery(actions.reloadOffline, reloadOfflineSaga),
  takeEvery(settingActions.setOnline, setOnlineSaga),
];
