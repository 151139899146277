import { history } from 'redux/store';
import { isObject } from 'lodash';

export const updateHashUrl = (hash = '') => {
  if (!history) return;
  const { location } = history;
  history.push({ ...location, hash });
};

export const updateSearchUrl = (search = '') => {
  if (!history) return;
  const { location } = history;
  history.push({ ...location, search });
};

export const convertJsonToQueryString = params => {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map(
      k =>
        `${esc(k)}=${esc(
          isObject(params[k]) ? JSON.stringify(params[k]) : params[k],
        )}`,
    )
    .join('&');
};

export const getQueryParamsUrl = () => {
  return decodeURI(window.location.search)
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .reduce((values, [key, value]) => {
      if (key) values[key] = value;
      return values;
    }, {});
};

export const prefetchCloudinaryImage = src => {
  if (!src) return;
  const img = new Image();
  img.src = `${process.env.REACT_APP_CLOUDINARY_URL}/w_400,h_400,c_crop,g_face,r_max/w_100/${src}`;
  img.onload = () => {
    // console.log('Loaded: ', src);
  };
};
