import React from 'react';
import PublicLayout from 'layout/PublicLayout';
import ResetPasswordForm from 'containers/ResetPassword';
import ResetPasswordWrapper from './styles';

const ResetPassword = () => (
  <PublicLayout>
    <ResetPasswordWrapper>
      <ResetPasswordForm />
    </ResetPasswordWrapper>
  </PublicLayout>
);

export default ResetPassword;
