import { notification } from 'antd';
import { call } from 'redux-saga/effects';
import Cookies from 'js-cookie';
// import { push } from 'connected-react-router';
// eslint-disable-next-line
import { persistor } from './store';

export function* apiWrapper(
  options = {
    isShowLoading: true,
    isShowSuccess: false,
    isShowError: true,
    redirectUrl: '',
  },
  apiFunction,
  ...payload
) {
  try {
    const response = yield call(apiFunction, ...payload);

    if (options?.isShowSuccess)
      notification.success({
        message: 'Success',
        duration: 3,
        description: options.description,
      });

    return response;
  } catch (error) {
    if (!options || options.isShowError) {
      notification.destroy();
      notification.error({
        message: 'Erreur',
        description:
          error?.response?.data?.message || "Une erreur s'est produite !",
      });
      // eslint-disable-next-line
      if (error?.response?.status == 401) {
        Cookies.remove('authToken');
        persistor.purge();
        window.location = '/login';
      }
    }

    throw new Error(error);
  }
}
