import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { Drawer, Form, Checkbox, Button, Icon } from 'antd';
import {
  filter,
  sortBy,
  find,
  keyBy,
  fill,
  flatten,
  values,
  groupBy,
} from 'lodash';
// import components
import Title from 'components/Title';
import Counter from 'components/Counter';
// import actions
import { actions } from 'redux/reports';
import { useParams } from 'react-router';
import { AddProductPopupStyles } from './styles';

const FormItem = Form.Item;

const AddProductPopup = ({ form, onClose, visible }) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showDrawerInfo, setShowDrawerInfo] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const dispatch = useDispatch();
  const { offlineId } = useParams();
  const newReportsToSync = useSelector((state) =>
    find(state.reports.listNewReportsToSync, (e) => `${e.id}` === offlineId),
  );
  const report = useSelector((state) => state.reports.currentNewReport);
  const currentNewReport = newReportsToSync || report;
  const listProducts = useSelector((state) => state.setting.listProducts);
  const listProductsOrdered = sortBy(
    listProducts,
    (product) => product.sort_order,
  );
  const productsEstimatedFarmerCount = values(
    groupBy(currentNewReport?.productsEstimatedFarmer, 'id'),
  ).map((group) => ({ ...group[0], qty: group.length }));

  const [productNumber, setProductNumber] = useState(
    keyBy(
      listProducts.map((product) => {
        const prodCount = find(
          productsEstimatedFarmerCount,
          (prod) => prod.id === product.id,
        );
        return {
          id: product.id,
          number: prodCount?.qty || 1,
        };
      }),
      'id',
    ),
  );
  const { getFieldDecorator } = form;

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let listSelectedProduct = [];
        const listTempProduct = filter(
          listProducts,
          (product) => values.products.indexOf(product.id) !== -1,
        );

        const listSelectedProductNumber = values.products.map(
          (id) => productNumber[id],
        );

        listTempProduct.forEach((product) => {
          const prodNumber = find(
            listSelectedProductNumber,
            (p) => p.id === product.id,
          );
          listSelectedProduct.push(fill(Array(prodNumber.number), product));
        });

        listSelectedProduct = flatten(listSelectedProduct);

        if (newReportsToSync) {
          dispatch(
            actions.updateNewReportToSync({
              ...newReportsToSync,
              productsEstimatedFarmer: listSelectedProduct,
            }),
          );
          onClose();
          return;
        }

        setIsSubmiting(true);

        dispatch(
          actions.addNewReport({
            productsEstimatedFarmer: listSelectedProduct,
          }),
        )
          .then(() => {
            setIsSubmiting(false);
            onClose();
          })
          .catch(() => {
            setIsSubmiting(false);
          });
      }
    });
  };

  const onUpdateNumber = (id, value) => {
    productNumber[id].number = value;
    setProductNumber(productNumber);
  };

  const onShowDrawerInfo = (info) => () => {
    setSelectedProduct(info);
    setShowDrawerInfo(true);
  };

  const onHideDrawerInfo = () => {
    setShowDrawerInfo(false);
    setSelectedProduct(null);
  };

  return (
    <Drawer
      placement="bottom"
      closable={false}
      onClose={onClose}
      visible={visible}
      height="100%"
      className="full-screen-drawer"
    >
      <AddProductPopupStyles>
        <div className="close-div">
          <Button onClick={handleSubmit} type="link" loading={isSubmiting}>
            {i18n.t('createReport.close')}
          </Button>
        </div>
        <Title
          title={i18n.t(
            'createReport.estimatedMarketingStep.addProductPopup.title',
          )}
          subtitle={i18n.t(
            'createReport.estimatedMarketingStep.addProductPopup.subTitle',
          )}
        />
        <Form>
          <div className="content-section">
            <FormItem className="product-section">
              {getFieldDecorator('products', {
                initialValue:
                  currentNewReport?.productsEstimatedFarmer?.map(
                    (pro) => pro.id,
                  ) ||
                  currentNewReport?.products?.map((pro) => pro.id) ||
                  [],
              })(
                <Checkbox.Group>
                  {listProductsOrdered.map((data, index) => (
                    <label key={index} className="label">
                      <Checkbox value={data.id} />
                      <div>
                        <a href="/#" onClick={onShowDrawerInfo(data)}>
                          <Icon type="info-circle" />
                        </a>
                        <div className="content-product-section">
                          <img
                            src={`${process.env.REACT_APP_CLOUDINARY_URL}/w_400,h_400,c_crop,g_face,r_max/w_100/${data.photo}`}
                            alt={data.nom}
                            title={data.nom}
                          />
                          <div className="name">{data.nom}</div>
                        </div>
                        <Counter
                          productId={data.id}
                          defaultValue={productNumber[data.id]?.number}
                          onUpdateNumber={onUpdateNumber}
                        />
                      </div>
                    </label>
                  ))}
                </Checkbox.Group>,
              )}
            </FormItem>
          </div>
        </Form>
        <Drawer
          height="100%"
          className="full-screen-drawer"
          closable
          onClose={onHideDrawerInfo}
          visible={showDrawerInfo}
        >
          <h2>Informations sur le produit</h2>
          {selectedProduct?.informations}
        </Drawer>
      </AddProductPopupStyles>
    </Drawer>
  );
};

AddProductPopup.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  form: PropTypes.object,
};

export default Form.create({ name: 'add_product_popup' })(AddProductPopup);
