import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  offlineActions: [],
};

const removeOfflineActions = (state, { payload }) => {
  state.offlineActions = state.offlineActions.filter(
    e => e.type !== payload.type,
  );
};

const addOfflineActions = (state, { payload }) => {
  state.offlineActions = [...state.offlineActions, payload];

  return state;
};

const authSlice = createSlice({
  name: 'reports',
  initialState: INITIAL_STATE,
  reducers: {
    removeOfflineActions,
    addOfflineActions,
    reloadOffline: () => {},
  },
});

export const { actions, reducer } = authSlice;

export default reducer;
